.loader-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
    text-align: center;
}

.loader-text {
    padding: 0px 15px;
}

.err-heading {
    font-size: 120%;
    font-weight: bold;
    color: #363636;
    margin-bottom: 10px;
}

.err-wrapper {
    margin: 30px;
    text-align: center;
    color: #363636;
}

.atag {
    color: #0179ff;
    text-decoration: none;
}

.zrbSpan {
    color: #3d7565;
}

.err-page-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.err-page-content-wrapper {
    color: #363636;
    font-size: 20px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.err-page-img-wrapper {
    align-self: center;
}

.err-page-heading {
    margin-top: 20px;
    font-size: 150%;
}

.root-app-wrapper {
    display: flex;
    flex-direction: column;
    /* overflow: scroll; */
    overflow: hidden;
    height: 100vh;
}

.root-app-wrapper.myJobsCorner {
    background: #f2f3f4;
    /* height: 100%; */
}

.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn {
    background-color: #fff;
}

.non-fixed-content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.sv-scroll-header {
    overflow: scroll;
    scroll-behavior: smooth;
}

.non-fixed-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.sv-fixed-top-header {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    scroll-behavior: smooth;
    height: calc(100vh - 70px);
}

.listings-bg-container {
    height: 100%;
}

.listings-bg-dialog .divider {
    background-color: #ececec;
    height: 1px;
    width: 100%;
}

.listings-bg-dialog .date-container .MuiSelect-icon {
    right: -5px;
}

.listings-bg-dialog .sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
    font-size: 20px;
    font-weight: bold;
    color: #121212;
}

.listings-bg-dialog .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn {
    background-color: #f6f6f6;
}

.app-wrapper .page-conatiner {
    display: flex;
    min-height: calc(100vh - 60px);
    flex-direction: column;
    margin-bottom: 40px;
}

.app-wrapper .bottom-aligned-interstitial-page-container {
    margin-bottom: 0 !important;
}

.listings-bg-dialog .app-wrapper .page-conatiner {
    min-height: unset;
}

@media (max-width: 600px) {
    .app-wrapper .page-conatiner {
        min-height: 100vh;
        margin-bottom: 10px;
    }

    .listings-bg-dialog .app-wrapper .page-conatiner {
        margin-bottom: 0;
    }

    .sv-fixed-top-header {
        height: auto;
    }
}

.custom-job-query .MuiAutocomplete-popupIndicator {
    color: #000;
    background-color: transparent;
    top: -20px;
    right: -50px;
}

.custom-job-query #survey-autocomplete-option {
    padding: 0;
    margin: 0;
    height: 56px;
    border: none;
    /* background-color: transparent; */
    position: relative;
    top: -15px;
    color: #000;
}

.custom-job-query.sf-jb-category .MuiAutocomplete-popupIndicator {
    top: -4px;
}

.custom-job-query .MuiAutocomplete-popupIndicator:hover {
    background-color: transparent;
}

.custom-job-query .MuiAutocomplete-popupIndicatorOpen {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

.custom-job-query.sf-jb-category #survey-autocomplete-option {
    top: 0;
}

.optin-cta-wrapper .optin-block.job-category-block {
    flex-direction: row;
}

.optin-block.job-category-block label.job-category.green-cta.sv_q_radiogroup_label {
    width: 48%;
    height: auto;
}

@media (max-width: 600px) {
    .optin-cta-wrapper .optin-block.job-category-block {
        flex-direction: column;
    }

    .optin-block.job-category-block label.job-category.green-cta.sv_q_radiogroup_label {
        width: 100%;
        height: auto;
    }
}

.footer-wrapper {
    height: 50px;
    flex-shrink: 0;
}

.input-with-icon.emailInput > div {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    height: 100%;
}

.input-with-icon.emailInput input#email {
    background: transparent;
    border: none;
    bottom: 2px;
    padding-left: 0.25em;
    padding-right: 0.5em;
}

/* Result */

.search-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px;
}

.search-bar-wrapper > div {
    margin: 5px;
}

.search-btn {
    height: 50px;
    /* border: 0px;
    border-radius: 2px; */
    padding: 3px 10px;
}

/* Result wrapper */

.result-title-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.result-title,
.popular-jobs-title {
    font-size: 130%;
    color: #559edf;
}

/* Jobs */

.jobs-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
}

.job-element-card {
    display: flex;
    flex-direction: row;
    height: 100px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0px;
    margin: 10px 15px;
    margin-bottom: 5px;
    justify-content: space-between;
}

.job-content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-around;
}

/* Pagination */

.pagination-wrapper {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
}

.pagination {
    padding: 0px;
    margin: 0px;
}

.pagination > li > a,
.pagination > li > a:hover,
.pagination > li > a:visited,
.pagination > li > a:focus,
.pagination > li > a:active {
    text-decoration: none;
    color: inherit;
    outline: 0;
}

.pagination > li {
    display: inline-block;
    padding: 9px;
    border: 1px solid #959596;
    margin: 3px;
    border-radius: 5px;
}

.pagination > li.disabled {
    display: none;
}

.pagination > li.active {
    background-color: rgba(0, 0, 0, 0.2);
}

/*
jobs loader
*/

.jobs-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 100;
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 120%;
    font-weight: bold;
    align-items: center;
}

.search-bar-wrapper {
    margin: 0 auto;
    width: 100%;
}

@media (max-width: 768px) {
    .search-bar-wrapper {
        flex-direction: column;
    }

    .btn-container {
        height: 48px;
    }
}

.btn-container .search-btn {
    height: 48px;
    margin-top: 5px;
    margin-left: 8px;
}

.search-bar-wrapper > div {
    margin: 0;
}

.nrml-txt {
    color: #294964;
    font-size: 16px;
    font-weight: bold;
    /* line-height: 2; */
}

.highlight {
    font-weight: 500;
    color: #0064ff;
    font-size: 18px;
    line-height: 1.33;
}

.search-bar-wrapper .search-btn {
    width: 85%;
    height: 48px;
    /* border-radius: 4px; */
}

.MuiTypography-root.job-list-header {
    background-color: #deebf7;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #1a66c8;
    text-align: center;
}

.job-list-header .bold {
    font-weight: bold;
}

.search-bar-wrapper .MuiOutlinedInput-input {
    padding: 10px 14px;
    height: 47px;
    box-sizing: border-box;
    font-size: 14px;
}

.result-title-wrapper {
    display: block;
    margin: 0px 0 10px;
    text-align: center;
}

.job-loc {
    color: #0064ff;
}

.result-title-wrapper .result-title {
    font-size: 14px;
    color: #294964;
    font-weight: bold;
    line-height: 24px;
}

.branding-txt {
    font-size: 11px;
    background-color: #ed6a0a;
    color: #fff;
    height: 20px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    width: 130px;
    padding: 0 5px;
    position: absolute;
    right: 0;
}

.static-branding-txt {
    font-size: 11px;
    background-color: #f94f00;
    color: #fff;
    height: 20px;
    border-radius: 0 4px;
    display: inline-flex;
    align-items: center;
    width: auto;
    position: absolute;
    right: 0;
    height: 11px;
    font-family: Roboto;
    font-size: 9.6px;
    font-weight: 900;
    padding: 4px 5px;
}

.job-description-creditAd-description {
    padding: 10px 12px;
    border-radius: 2px;
    margin-top: 6px;
    background-color: #eef8ff;
}

.job-content-wrapper-creditAd {
    width: 100% !important;
}

.search-icon {
    width: 10px;
    height: 10px;
    padding: 2px 3px 0 0;
}

.divider {
    background-color: #88a1ad;
    height: 1px;
    width: 100%;
}

.jobs-list-wrapper .job-element-card {
    margin: 0 0 10px;
    padding: 10px 0px 0px 10px;
    background: #fff;
    border: 1px solid #d8e0e7;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
}

.jobs-list-wrapper .job-element-card.job-element-card-fulfillment-design {
    border: unset !important;
}
.link-container {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
}

.jobs-list-wrapper .job-element-card .job-content-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding: 0px;
    -webkit-tap-highlight-color: transparent;
    width: 80%;
}

.job-element-card.dynamic-feed {
    padding: 20px 0px 0px 10px;
    border: solid 1px #b1d1ea;
    background-color: #e8f5ff;
}

.listing-item {
    opacity: 0;
    -webkit-transition: opacity 1s ease-out;
    transition: opacity 1s ease-out;
    overflow: hidden;
}

.listing-item.content {
    opacity: 1;
}

.static-listings-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.static-listings-wrapper .title {
    /* height: 16px; */
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: center;
    color: #273b4a;
    width: 100%;
}

.static-listings-wrapper .focus {
    color: #3f8dd3;
}

.job-element-card.static-feed {
    padding: 0;
    border: solid 2px #d8e0e7;
    /* border: solid 1.5px #f94f00; */
    width: 100%;
    cursor: pointer;
}

/*Week1 starts*/
.jobs-list-wrapper.static-list.week1 .job-element-card.static-feed {
    border: solid 2px #f94f00;
}

.jobs-list-wrapper.static-list.week1 .static-feed-right {
    display: none;
}

/*Week1 ends*/

/*Week2 starts*/
.jobs-list-wrapper.static-list.week2 .job-element-card.static-feed {
    border: solid 2px #54ae00;
}

.jobs-list-wrapper.static-list.week2 .static-branding-txt {
    display: none;
}

/*Week2 ends*/

/*Week3 starts*/
.jobs-list-wrapper.static-list.week3 .job-element-card.static-feed {
    border: solid 2px #54ae00;
}

.jobs-list-wrapper.static-list.week3 .static-branding-txt {
    display: none;
}

.jobs-list-wrapper.static-list.week3 .job-apply-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-right: 15px;
}

.jobs-list-wrapper.static-list.week3 .static-feed-right {
    width: 50px;
    height: 50px;
    background-color: #54ae00;
    border: 2px solid #509e07;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

@media (max-width: 360px) {
    .jobs-list-wrapper.static-list.week3 .static-feed-right {
        width: 40px;
        height: 40px;
    }
}

/*Week3 ends*/

/*Week1 starts*/
.jobs-list-wrapper.static-list.week4 .job-element-card.static-feed {
    border: solid 2px #a663f6;
}

.jobs-list-wrapper.static-list.week4 .static-branding-txt {
    background-color: #a663f6;
}

.jobs-list-wrapper.static-list.week4 .static-feed-right {
    display: none;
}

/*Week1 ends*/

.job-element-card.static-feed .job-apply-wrapper a.MuiButton-text {
    margin-right: 0;
    margin: 0;
    position: absolute;
    right: 30px;
}

.static-feed-right {
    width: 25px;
    height: 100%;
    background-color: #54ae00;
    display: flex;
    justify-content: center;
    align-items: center;
}

.job-element-card.static-feed .job-content-wrapper {
    display: flex;
    flex-direction: column;
}

.job-element-card.static-feed .job-logo-wrapper.static-logo-wrapper.MuiAvatar-root {
    width: 46px;
    height: 46px;
    background-color: #e3e9ed;
    margin-right: 10px;
    padding-right: 0;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.job-element-card.static-feed .job-logo-wrapper.static-logo-wrapper.MuiAvatar-root .static-logo {
    width: 30px;
    height: 30px;
}

.job-element-card.static-feed .job-content-title-info {
    display: flex;
    margin-bottom: 10px;
}

.job-element-card.static-feed .job-content-title-info .job-description-wrapper.static-desc {
    padding: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.job-element-card.static-feed .job-content-title-info .job-description-wrapper.static-desc h1 {
    line-height: normal;
    padding-bottom: 0;
}

.job-element-card.static-feed
    .job-content-title-info
    .job-description-wrapper.static-desc
    .job-description-creditAd-description
    h1 {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #818b92;
}

.jobs-list-wrapper .job-element-card.static-feed .job-content-wrapper {
    padding: 10px;
}

.job-element-card.static-feed .job-content-wrapper .static-anc,
.job-element-card.static-feed .job-content-wrapper .static-anc span {
    font-size: 14px;
    text-decoration: none;
    color: #408dd3;
}

.jobs-list-wrapper.static-list {
    width: 100%;
}

.job-description-wrapper.static-desc {
    width: 100%;
}

.job-element-card .job-content-wrapper .job-description-wrapper .static-anc {
    line-height: 1;
    font-size: 12px;
}

.static-brand-img {
    max-width: 100%;
    width: auto;
    max-height: 200px;
    height: auto;
}

@media (max-width: 600px) {
    .job-element-card .job-content-wrapper .job-description-wrapper .static-anc {
        font-size: 11px;
    }

    .static-brand-img {
        max-height: 100%;
    }
}

.arrow-icon {
    width: 18px;
}

.bolt-icon {
    width: 5px;
    height: 11px;
    padding-right: 2px;
    padding-top: 2px;
}

.job-element-card .job-content-wrapper .job-description-wrapper.static-desc h1 {
    line-height: 1.3;
}

.job-element-card .job-content-wrapper .job-description-wrapper {
    padding: 0px 0px 10px 0px;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
}

.job-element-card
    .job-content-wrapper
    .job-description-wrapper.job-description-wrapper-fulfillment-design {
    width: 65%;
}

.job-element-card .job-content-wrapper .job-description-wrapper h1 {
    font-size: 16px;
    font-weight: 500;
    color: #273b4a;
    line-height: 1.14;
    padding-bottom: 5px;
}

.job-element-card .job-content-wrapper .job-description-wrapper h2 {
    font-size: 12px;
    color: #41434d;
    line-height: 1.14;
    padding-bottom: 5px;
}

.job-element-card .job-content-wrapper .job-description-wrapper a {
    font-size: 12px;
    color: #3f8dd3;
    text-decoration: none;
    line-height: 1.14;
}

.jobs-list-wrapper .job-element-card .job-logo-wrapper {
    padding-right: 10px;
}

.job-logo-wrapper-fulfillment-design .MuiAvatar-rounded {
    width: 58px;
    height: 58px;
}

.job-apply-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 4px;
    width: 20%;
}

.job-apply-wrapper-fulfillment-design {
    justify-content: center;
    width: 35%;
}

.job-apply-wrapper .job-highlight {
    border-radius: 90px 0px 0px 90px;
    display: inline-flex;
    padding: 0px 7px;
    justify-content: center;
}

.job-highlightv2 {
    padding: 5px 0;
}

.job-apply-wrapper .customimg {
    width: auto;
    height: auto;
    display: inline-flex;
}

.customimg.highlightImg1 {
    max-width: 137px;
    max-height: 30px;
}

.customimg.highlightImg2 {
    max-width: 95px;
    max-height: 15px;
    padding-right: 5px;
}

.customimg.highlightImg3 {
    max-width: 137px;
    max-height: 30px;
}

.highlighttext1 {
    position: absolute;
    left: 85px;
    /* top: -5px; */
    padding: 7px 0;
    font-weight: bold;
    color: #985823;
    font-size: 14px;
}

.highlighttext2 {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #495255;
}

.highlighttext3 {
    position: absolute;
    left: 85px;
    /* top: -5px; */
    padding: 7px 0;
    font-weight: bold;
    color: #526942;
    font-size: 14px;
}

.job-apply-wrapper .job-highlight .job-highlight-text {
    padding: 2px;
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: 11px;
    letter-spacing: normal;
    color: white;
    display: inline-flex;
    align-items: center;
    font-size: 11px;
}

.job-apply-wrapper .job-highlight .job-highlight-text.job-highlight-text-fulfillment-design {
    font-size: 9px;
}

.job-apply-wrapper a.MuiButton-text {
    padding: 6px 4px;
    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
    text-transform: none;
    line-height: 1;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #53ae02;
}

.job-apply-wrapper .job-apply-button-fulfillment-design {
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(83, 174, 2, 1);
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation: pulse 2s infinite;
            animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(83, 174, 2, 0.7);
    }

    70% {
        -webkit-transform: scale(1);
                transform: scale(1);
        box-shadow: 0 0 0 5px rgba(83, 174, 2, 0);
    }

    100% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(83, 174, 2, 0);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(83, 174, 2, 0.7);
    }

    70% {
        -webkit-transform: scale(1);
                transform: scale(1);
        box-shadow: 0 0 0 5px rgba(83, 174, 2, 0);
    }

    100% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(83, 174, 2, 0);
    }
}

.pagination-wrapper {
    margin: 20px 0 30px;
}

.bold {
    font-weight: 900;
    font-size: 15px;
}

.job-logo-wrapper.static-logo-wrapper.MuiAvatar-root {
    width: 48px;
    height: 48px;
    background-color: #f2f3f5;
    margin-right: 10px;
    padding-right: 0;
}

.static-logo {
    max-width: 40px;
    max-height: 40px;
    width: 100%;
    height: 100%;
}

.pagination-inner-wrapper button {
    display: inline-block;
    padding: 9px 14px;
    border: 1px solid #bbbbbb;
    margin: 3px;
    border-radius: 5px;
    color: #4a90e2;
    width: 40px;
    height: 38px;
    box-sizing: border-box;
    cursor: pointer;
}

.pagination-inner-wrapper button.active {
    background: none;
    color: #9b9b9b;
    font-weight: 600;
}

.header-title {
    font-size: 12px;
    line-height: 16px;
    color: #a3a9ae;
    word-wrap: break-word;
    margin-bottom: 0px;
    font-family: 'Roboto', sans-serif;
}

.jobs-list-wrapper .job-element-card.job-element-card-fulfillment-design .job-content-wrapper {
    width: 90%;
}

.job-element-card.job-element-card-fulfillment-design
    .job-content-wrapper
    .job-description-wrapper.job-description-wrapper-fulfillment-design {
    width: 75%;
}

.jobs-list-wrapper .job-element-card.job-element-card-fulfillment-design {
    border: unset !important;
    position: relative;
}

.job-apply-wrapper .job-highlight-fulfillment-design {
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 2px;
    min-height: 18px;
}
.job-highlight-fulfillment-design .job-highlight-picture {
    display: flex;
    align-items: center;
}

.job-element-card.job-element-card-fulfillment-design
    .job-content-wrapper
    .job-description-wrapper
    h1 {
    min-height: 28px;
}

/*MJC popup css starts*/
.mjc-pop-main-wrapper {
    position: relative;
}

.mjc-pop-main-wrapper .MuiDialog-paper {
    width: 400px;
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
}

@-webkit-keyframes bounceInDown {
    0%,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes bounceInDown {
    0%,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.mjc-pop-main-wrapper .MuiDialogContent-root {
    padding: 0 !important;
}

.mjc-pop-logo {
    text-align: center;
    border-bottom: 2px solid #244766;
    padding: 20px 15px;
    background: #244766;
}

.mjc-pop-main-wrapper .mjc-pop-logo .close svg {
    width: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.mjc-pop-main-wrapper .mjc-pop-logo .close svg g g {
    fill: white;
}

.mjc-pop-logo img {
    width: 170px;
}

.disclaimer-with-error-msg h3 a {
    color: #227ddc;
}

.mjc-pop-main-wrapper .emailInput input {
    text-align: center;
}

.padding-pop-resp {
    padding: 30px 30px 20px;
}

.mjc-pop-main-wrapper .disclaimer-with-error-msg .disclaimer-wrapper p {
    margin-top: 0;
}

.xml-pop-up-container {
    cursor: pointer;
}

@media (max-width: 400px) {
    .mjc-pop-main-wrapper .disclaimer-with-error-msg .disclaimer-wrapper {
        font-size: 8px;
    }
}
@media (max-width: 500px) {
    .mjc-pop-main-wrapper .MuiDialog-paper {
        margin: 20px;
        width: 100%;
    }
    .padding-pop-resp {
        padding: 15px 15px 10px;
    }
}
/*MJC popup css ends*/

.interstitial-ads-main-wrapper {
    background-color: rgba(0, 0, 0, 0.8);
}
.interstitial-ads-main-wrapper .MuiDialog-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 225px;
}
.interstitial-ads-jobs-conatiner .job-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}
.interstitial-ads-main-wrapper .MuiDialog-paperScrollPaper {
    overflow-y: inherit;
    height: calc(100% - 50px);
    background: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 10px;
    /* max-width: 400px; */
}

.interstitial-ads-block {
    width: 330px;
    background: white;
    text-align: center;
    position: relative;
    border-radius: 4px;
}

.interstitial-ads-block .interstitial-ads-info-block {
    background-color: white;
    border-radius: 2px;
}

.interstitial-ads-block .interstitial-ads-sub-info {
    font-size: 16px;
    color: #294964;
    text-align: center;
    line-height: 1.2;
    margin: 0;
}
.interstitial-ads-block .MuiDialogContent-root {
    padding: 0px;
}

.interstitial-ads-block .interstitial-ads-btn-wrapper {
    background: white;
    padding: 0 24px 20px;
    position: relative;
    border-radius: 0 0 4px 4px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.interstitial-ads-block .interstitial-ads-sub-info .bold-text {
    font-size: 22px;
    font-weight: bold;
    line-height: 2.2;
}

.interstitial-ads-block.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
}
.interstitial-ads-jobs-conatiner .jobs-list-wrapper {
    display: flex;
    flex-direction: column;
}
.interstitial-ads-jobs-conatiner .jobs-list-wrapper .job-element-card {
    display: flex;
}
.interstitial-ads-jobs-conatiner .jobs-list-wrapper .job-content-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    flex-direction: column;
    height: 100%;
}
.interstitial-ads-jobs-conatiner .job-description-container .job-location {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 13px;
    color: #688093;
    line-height: 1.88;
    text-align: left;
}
.interstitial-ads-jobs-conatiner .job-description-container .job-description {
    color: #273b4a;
    line-height: 20px;
    text-align: left;
    font-weight: 500;
}

.interstitial-ads-jobs-conatiner .jobs-list-wrapper .job-logo-wrapper {
    display: flex;
    justify-content: center;
    padding-right: 10px;
}
.interstitial-ads-jobs-conatiner .jobs-list-wrapper .job-description-wrapper {
    display: flex;
}
.interstitial-ads-jobs-conatiner
    .jobs-list-wrapper
    .job-description-wrapper
    .job-description-container {
    display: flex;
    flex-direction: column;
}
.interstitial-ads-info-block .header {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    background-color: #235375;
}
.interstitial-ads-info-block .header .trending-btn {
    height: 24px;
    /* background-color: #ec6e1a; */
    border-radius: 2px;
    text-transform: capitalize;
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 500;
}
.interstitial-ads-info-block .header .trending-btn .trending-icon {
    width: 16px;
    height: 16px;
    padding-right: 10px;
}
.interstitial-ads-info-block .header .close .close-icon {
    width: 20px;
    height: 20px;
}
.interstitial-ads-jobs-conatiner .carousel .control-dots {
    display: none;
}
.interstitial-ads-jobs-conatiner .carousel .carousel-status {
    display: none;
}
.interstitial-ads-jobs-conatiner .carousel .slide {
    background-color: white;
}
.interstitial-ads-jobs-conatiner .button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    background-color: #235375;
    align-items: center;
}

.interstitial-ads-jobs-conatiner .button-container .btn {
    height: 54px;
    width: 122px;
    background-color: #618aa6;
    /* background-image: linear-gradient(to bottom, #a2b1c4, #9ba9bb); */
    color: white;
    border-radius: 2px;
    font-size: 18px;
    font-weight: bold;
}
.interstitial-ads-jobs-conatiner .button-container .btn.primary {
    width: 170px;
    height: 54px;
    border-radius: 2px;
    border: solid 1px #458e00;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#55ae00), to(#4d9e00));
    background-image: -webkit-linear-gradient(top, #55ae00, #4d9e00);
    background-image: linear-gradient(to bottom, #55ae00, #4d9e00);
}
.interstitial-ads-jobs-conatiner .button-container .btn.primary .arrow-icon {
    width: 7px;
    height: 11px;
    margin-top: 12px;
    margin-left: 5px;
}
.jobs-list-wrapper .carousel-root .carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: unset;
}
.interstitial-ads-jobs-conatiner .carousel .control-next.control-arrow:before {
    content: url('/images/carousel-right-arrow.svg');
    border: 0;
    position: absolute;
    top: 25%;
    left: -18px;
}
.interstitial-ads-jobs-conatiner .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
}
.interstitial-ads-jobs-conatiner .carousel .control-prev.control-arrow:before {
    content: url('/images/carousel-left-arrow.svg');
    border: 0;
    position: absolute;
    top: 25%;
    right: -18px;
}
@-webkit-keyframes bounceInDown {
    0%,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes bounceInDown {
    0%,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@-webkit-keyframes tada {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@keyframes tada {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-iteration-count: 4;
            animation-iteration-count: 4;
}

@media screen and (max-width: 360px) {
    .interstitial-ads-main-wrapper .MuiDialog-container {
        height: 100%;
    }
}

@media only screen and (min-width: 361px) and (max-width: 500px) {
    .interstitial-ads-main-wrapper .MuiDialog-container {
        height: 100%;
    }
}
@media (max-width: 339px) {
    .interstitial-ads-block {
        width: calc(100vw - 20px);
        margin: 10px;
        border-radius: 2px;
    }
    .interstitial-ads-jobs-conatiner .button-container .btn.primary {
        width: 150px;
    }
    .interstitial-ads-jobs-conatiner .button-container .btn {
        width: 111px;
    }
}

.listLogoWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.listLogoWrapper .MuiList-padding {
    flex-direction: column;
    /* width: 529px; */
}

.listLogoWrapper .MuiList-padding li {
    justify-content: flex-start;
}

.lists-info li {
    justify-content: normal;
}

.listLogoWrapper .MuiList-padding li h2 {
    /* line-height: 24px; */
    padding-left: 28px;
}

.image-txt-wrapper {
    position: relative;
}

.image-txt-wrapper .image-wrapper {
    position: absolute;
    left: 0;
}

.listLogoWrapper .img-avatar {
    padding: 0;
    border-radius: 0;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    justify-content: center;
    background-color: transparent;
}

.explore-jobs-btns .MuiButton-textPrimary {
    font-size: 1.4rem;
    padding: 10px 20px;
    width: 330px;
    box-sizing: border-box;
    margin: 10px;
}

.hand-bg-wrapper.hand-bg {
    width: 100%;
    height: 300px;
    background: center bottom;
    background-size: contain;
    margin-top: 30px;
}

.sub-footer-wrapper {
    padding: 15px;
    text-align: center;
    box-sizing: border-box;
}

.sub-footer-wrapper h3 {
    color: #fff;
}

.sub-footer-wrapper .MuiGrid-grid-xl-3 {
    flex-basis: auto;
    max-width: 33.33%;
}

/* .app-wrapper .disclaimer-with-error-msg {
    margin: 0 0 20px;
} */

/* .app-wrapper .disclaimer-with-error-msg .MuiCheckbox-root {
    padding: 0;
    top: 10px;
    left: -5px;
} */

.disclaimer-with-error-msg label {
    margin: 0;
}

.disclaimer-with-error-msg .Mui-required {
    position: relative;
    top: 12px;
}

.container {
    width: 1140px;
    margin: 0 auto;
}

.email-opt-page-wrapper.iframe-wrapper {
    height: 100%;
    padding-top: 30px;
    padding-bottom: 20px;
}

.iframe-wrapper .container iframe {
    width: 100%;
    border: none;
}

.iframe-wrapper .container {
    width: 800px;
    padding-top: 30px;
}

.iframe-wrapper .container iframe body {
    background-color: transparent;
    max-width: 100%;
    padding: 0;
}

.email-opt-page-wrapper.iframe-wrapper .article-img {
    height: 370px;
}

.email-opt-page-wrapper.iframe-wrapper .article-img img {
    padding: 0 8px 30px;
    width: 100%;
    box-sizing: border-box;
}

.contactus-form-wrapper {
    background: #eaeff2;
    /* padding: 0 20px 20px; */
    margin-top: 20px;
    border-radius: 0 0 4px 4px;
}

.contactus-form-wrapper .contactus-form {
    padding: 20px;
}

/* .contactus-form-wrapper .contactus-form-title {
    line-height: 22px;
    color: #304351;
    margin-bottom: 12px;
    text-transform: uppercase;
} */

.contactus-form-wrapper .contactus-form input.form-control,
select,
.apply-job-form .input-wrap input {
    border: 1px solid #d8e0e7;
    font-size: 14px;
    padding: 10px 15px;
    height: 42px;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.contactus-form-wrapper .contactus-form textarea.form-control {
    border: 1px solid #d8e0e7;
    font-size: 14px;
    resize: none;
    height: 93px;
    overflow: scroll;
    border-radius: 4px;
    width: calc(100% - 30px);
    margin-bottom: 15px;
    padding: 10px 15px;
}

.contactus-form-wrapper .contactus-form input.form-control::-webkit-input-placeholder, select::-webkit-input-placeholder, .contactus-form-wrapper .contactus-form textarea.form-control::-webkit-input-placeholder, .apply-job-form .input-wrap input::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccd5db;
    /* Firefox */
}

.contactus-form-wrapper .contactus-form input.form-control::-moz-placeholder, select::-moz-placeholder, .contactus-form-wrapper .contactus-form textarea.form-control::-moz-placeholder, .apply-job-form .input-wrap input::-moz-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccd5db;
    /* Firefox */
}

.contactus-form-wrapper .contactus-form input.form-control::-ms-input-placeholder, select::-ms-input-placeholder, .contactus-form-wrapper .contactus-form textarea.form-control::-ms-input-placeholder, .apply-job-form .input-wrap input::-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccd5db;
    /* Firefox */
}

.contactus-form-wrapper .contactus-form input.form-control::placeholder,
select::placeholder,
.contactus-form-wrapper .contactus-form textarea.form-control::placeholder,
.apply-job-form .input-wrap input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccd5db;
    /* Firefox */
}

.contactus-form-wrapper .contactus-form input.form-control:focus,
select:focus,
.contactus-form-wrapper .contactus-form textarea.form-control:focus,
.apply-job-form .input-wrap input:focus {
    outline: none;
    box-shadow: none;
}

.home-page-wrapper .contactus-form-wrapper .contactus-submit {
    width: 100%;
}

.green-btn {
    border-color: 1px solid rgb(76, 161, 7);
    border-radius: 3px;
    background-color: rgb(92, 185, 16);
    color: #fff;
    padding: 12px 12px;
    height: 47px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
}

.contactus-form-wrapper .contactus-submit.green-btn > img,
.apply-job-form .green-btn > img {
    position: relative;
    top: 1px;
    left: 10px;
    -webkit-transition: transform 0.3s ease-out;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 10px;
    height: 13px;
}

.green-btn:hover {
    color: #fff;
}

.green-btn:hover img {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
}

.email-opt-page-wrapper .email-opt-fold {
    display: flex;
    flex-direction: row;
    border: 1px solid #d8e0e7;
    border-radius: 10px;
    padding: 40px;
    align-items: center;
    justify-content: center;
}

.email-opt-img-wrapper img {
    width: 479px;
}

.email-opt-content {
    text-align: center;
}

.email-opt-content .main-title {
    font-size: 26px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 20px;
}

.email-opt-content .sub-title {
    font-size: 18px;
    line-height: 22px;
    color: #9aabb8;
    margin-bottom: 22px;
}

.email-opt-content .contactus-form-wrapper.email-opt-form-wrapper {
    background: #fafafa;
    padding: 0;
    width: 400px;
    margin: 0 auto;
}

.email-opt-content .email-opt-form-wrapper .email-opt-form .green-btn {
    width: 100%;
}

.email-opt-content .email-opt-form-wrapper .email-opt-form .green-btn:focus {
    outline: none;
    box-shadow: none;
}

.email-opt-content .email-opt-form-wrapper .email-opt-form .green-btn > img {
    top: 1px;
}

.email-opt-content .email-opt-form-wrapper .email-opt-form .term-for-use,
.email-opt-content .email-opt-form-wrapper .email-opt-form .term-for-use a {
    font-size: 10px;
    color: #9aabb8;
    margin-top: 10px;
    text-decoration: none;
}

.email-opt-content .email-opt-form-wrapper .email-opt-form .select-wrapper select.minimal {
    background-color: #fff;
    padding-right: 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.email-opt-content
    .email-opt-form-wrapper
    .email-opt-form
    .select-wrapper
    select.minimal
    option:not(:first-child) {
    color: #000;
}

.email-opt-content .email-opt-form-wrapper .email-opt-form .select-wrapper {
    position: relative;
}

.email-opt-content .email-opt-form-wrapper .email-opt-form .select-wrapper .arrow-down {
    position: absolute;
    top: 11px;
    right: 20px;
}

.email-opt-content .email-opt-form-wrapper .email-opt-form .select-wrapper .arrow-down img {
    width: 8px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.contactus-form-wrapper.email-opt-form-wrapper.subscribe-submit-message-btn {
    background-color: transparent;
}

.contactus-form-wrapper.email-opt-form-wrapper.subscribe-submit-message-btn button {
    width: 100%;
}

.subscribe-submit-message-fold .main-title {
    line-height: 32px;
}

.email-opt-page-wrapper {
    /* height: calc(100vh - 132px);
    overflow: scroll; */
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.unsubscribe-wrapper .title-wrapper .unsubscribe-main-title {
    font-size: 26px;
    line-height: 22px;
    font-weight: 500;
}

.unsubscribe-wrapper .title-wrapper .unsubscribe-sub-title {
    font-size: 18px;
    line-height: 22px;
    color: #9aabb8;
    margin-top: 20px;
}

.progress-main-wrapper {
    height: 30px;
}

.progress-element-inner {
    height: 30px;
}

#progress-element {
    height: 30px;
    position: relative;
}

.lg-variation.bounce {
    -webkit-animation: bounce 1.9s infinite ease-in-out;
            animation: bounce 1.9s infinite ease-in-out;
    margin: 10px auto -20px;
}

@-webkit-keyframes bounce {
    0%,
    20%,
    60%,
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }

    80% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}

@keyframes bounce {
    0%,
    20%,
    60%,
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }

    80% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}

/* #progress-element:after {
    content: " ";
    position: absolute;
    right: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 15px solid black;
    border-bottom: 15px solid transparent;
    z-index: 1;
} */

.app-wrapper .agree-reg2 .checkbox-btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
}

/* New flow Changes starts */
.app-wrapper .input-with-icon {
    position: relative;
    padding: 15px 20px 15px 45px;
}

.app-wrapper .desktop-lander .desktop-margin {
    margin: 10px 0 15px;
}

.app-wrapper .input-with-icon .input-icon {
    position: absolute;
    left: -28px;
    top: 0;
    height: 100%;
}

.app-wrapper .desktop-lander {
    margin: 100px auto 20px;
    width: 80%;
}

.app-wrapper .desktop-lander-submit {
    display: flex;
    margin: 20px auto;
}

@media (min-width: 1029px) {
    .desktop-lander-margin {
        padding: 10px;
    }

    .app-wrapper .desktop-lander-submit {
        display: flex;
        margin: 40px auto;
        margin-right: 10px;
    }

    .app-wrapper .desktop-lander {
        margin: 100px auto 20px;
        width: 65%;
    }
}

@media (max-width: 1028px) {
    .app-wrapper .desktop-lander {
        margin: 100px auto 20px;
        width: 70%;
    }
}

.app-wrapper .input-with-icon .input-icon img {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.app-wrapper .input-with-icon p.Mui-error {
    position: absolute;
    bottom: -7px;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    white-space: nowrap;
}

.app-wrapper .date-input {
    color: #a8a8a8;
    width: 100%;
    border: 1px solid #88a1ad;
    height: 58px;
    margin: 5px 0;
    /* padding: 15px 20px; */
    padding-left: 44px;
    position: relative;
    font-size: 18px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #fff;
}

.app-wrapper .date-input .input-with-icon {
    padding: 15px 0 15px 0;
    margin: 0;
    border: none;
    height: 56px;
}

.app-wrapper .date-input.date-input-error {
    border: 1px solid #e10000;
}

.app-wrapper .date-input .input-with-icon .MuiSelect-select {
    border-right: 1px solid #f2f2f2;
    margin-top: 4px;
}

.app-wrapper .date-input .input-with-icon .MuiSelect-icon {
    right: 10px;
}

.app-wrapper .date-input .input-with-icon label {
    font-size: 22px !important;
    margin-left: 10px !important;
}

.app-wrapper .align-input-side {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.align-input-side .desktop-margin {
    margin-right: 15px;
}

.desktop-hide {
    display: none;
}

.app-wrapper .desktop-bold {
    font-weight: 800;
}

.green-theme-underline label + .MuiInput-formControl {
    margin-top: 0;
}

.green-theme-underline label + .MuiInput-formControl:before,
.green-theme-underline label + .MuiInput-underline:hover:not(.Mui-disabled):before,
.MuiInput-underline:after,
.MuiInput-underline.Mui-error:after {
    border-bottom: none !important;
}
/*New flow Changes ends*/

/*Facebook traffic path css starts*/
.root-app-wrapper.myJobsCorner.fb-bg-main-warpper {
    background-color: #3f5894;
}

.fb-bg-main-warpper .app-wrapper .fb-bg-warpper h6,
.root-app-wrapper.myJobsCorner.fb-bg-main-warpper .fb-bg-flow {
    color: #fff;
}

.root-app-wrapper.myJobsCorner.fb-bg-main-warpper .fb-bg-warpper .logo-wrap {
    background: #fff;
    width: auto;
    height: auto;
    padding: 10px;
    border-radius: 50%;
    margin-bottom: 15px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
}
.root-app-wrapper.myJobsCorner.fb-bg-main-warpper .fb-bg-warpper .logo-wrap img {
    display: flex;
    object-fit: contain;
}
.root-app-wrapper.myJobsCorner.fb-bg-main-warpper .fb-bg-warpper .sub-title {
    color: #b2c1e6;
    font-size: 18px;
}
.root-app-wrapper.myJobsCorner .sub-title .kw-text {
    text-transform: capitalize;
    font-weight: bold;
}
#no-thanks-text.no-thanks-bg-flow,
.fb-bg-main-warpper .fb-loader-txt {
    color: #fff;
}
.fb-bg-main-warpper .hide-progressbar,
.offer-path-container
    .offer-path-wrapper.fb-offer-info
    .offer-path-headings-wrapper
    .matches-sub-heading {
    display: none;
}

.offer-path-container
    .offer-path-wrapper.fb-offer-info
    .offer-path-headings-wrapper
    .MuiLinearProgress-colorPrimary {
    background-color: #fff;
}
.offer-path-container .offer-path-wrapper.fb-offer-info .offer-path-headings-wrapper,
.offer-path-wrapper.fb-offer-info
    .offer-path-headings-wrapper
    .matches-title
    .dormant-header-part
    .value-display-resp {
    color: #fff;
    margin: 0px 0 20px;
    width: 100%;
    padding: 20px 0 5px;
}
.offer-path-container
    .offer-path-wrapper.fb-offer-info
    .offer-path-headings-wrapper
    .matches-title
    .dormant-header-part {
    color: rgba(255, 255, 255, 0.5) !important;
}
.offer-path-container
    .offer-path-wrapper.fb-offer-info
    .offer-path-headings-wrapper
    .offer-path-progress
    .MuiLinearProgress-bar {
    background-color: #5cb910;
}
.offer-path-container
    .offer-path-wrapper.fb-offer-info
    .offer-path-headings-wrapper
    .matches-title {
    color: #fff;
}
.fb-bg-main-warpper .MuiInputLabel-outlined.MuiInputLabel-shrink {
    -webkit-transform: translate(10px, -9px) scale(0.75);
            transform: translate(10px, -9px) scale(0.75);
}
.fb-bg-main-warpper .app-wrapper .fb-bg-warpper .input-with-icon .MuiFormLabel-filled,
.fb-bg-main-warpper
    .app-wrapper
    .fb-bg-warpper
    .input-with-icon
    .MuiFormLabel-root.Mui-focused.MuiInputLabel-shrink,
.fb-bg-main-warpper
    .app-wrapper
    .fb-bg-warpper
    .input-with-icon
    .MuiFormLabel-root.Mui-error.MuiInputLabel-shrink,
.fb-bg-main-warpper
    .app-wrapper
    .fb-bg-warpper
    .input-with-icon
    .MuiFormLabel-root.MuiFormLabel-filled.Mui-focused.MuiInputLabel-shrink.Mui-error {
    padding: 3px 8px;
}
.fb-bg-main-warpper .app-wrapper .fb-bg-warpper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.fb-bg-main-warpper .app-wrapper .fb-bg-warpper .fb-white-bgwarpper {
    display: flex;
    flex-direction: column;
    margin: 30px 100px 10px;
    background: #fff;
    border-radius: 12px;
    padding: 10px 0 30px;
}
.hiring-text,
.hide-briefcase {
    display: none;
}

@media (max-width: 400px) {
    .fb-bg-main-warpper .app-wrapper .fb-bg-warpper .title-wrapper h6 {
        margin-bottom: 10px;
    }
    .fb-bg-main-warpper .fb-bg-warpper .title-wrapper h6 {
        margin-bottom: 30px;
    }
    .fb-bg-main-warpper .app-wrapper #landing .fb-bg-warpper .form-warpper {
        justify-content: flex-start;
    }
}
@media (max-width: 767px) {
    .fb-bg-main-warpper .app-wrapper .fb-bg-warpper .title-wrapper,
    .fb-bg-main-warpper .app-wrapper .fb-bg-warpper .form-warpper {
        flex: 1 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .fb-bg-main-warpper .fb-bg-warpper .title-wrapper h6 {
        margin-bottom: 60px;
    }
    .fb-bg-main-warpper .app-wrapper .fb-bg-warpper {
        height: calc(100vh - 50px);
    }
    .fb-bg-main-warpper .app-wrapper .fb-bg-warpper .title-wrapper .hiring-text {
        display: block;
        font-size: 24px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 20px;
    }
    .fb-bg-main-warpper .app-wrapper .fb-bg-warpper .fb-btn-warpper {
        margin-bottom: 10px;
    }
    .offer-path-wrapper.fb-offer-info .offer-path-headings-wrapper {
        margin: 0px 0 10px;
        padding: 15px 0 5px;
    }
    .offer-path-container .offer-path-wrapper.fb-offer-info .offer-path-headings-wrapper {
        margin: 0px 0 10px;
    }
}

/* Facebook traffic path css ends */

/* All Inbox Email Changes starts */
.all-inbox-email-wrapper {
    text-align: center;
    padding: 15px 0;
    margin: 15px 0 0;
    border-top: 1px solid #bfcdd7;
    border-bottom: 1px solid #bfcdd7;
}
.all-inbox-email-wrapper .all-inbox-email-title {
    font-size: 16px;
    color: #294964;
    line-height: 20px;
    font-weight: bold;
}
.all-inbox-email-wrapper .all-inbox-email-table {
    width: 100%;
    margin-top: 15px;
}
.all-inbox-email-wrapper .all-inbox-email-table tr td {
    color: #007aff;
    font-size: 14px;
    width: 50%;
    line-height: 18px;
    cursor: pointer;
}
.all-inbox-title-wrapper {
    color: #294964;
    padding: 10px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 15px;
}
.all-inbox-title-wrapper .all-inbox-title {
    font-size: 2rem;
    margin-bottom: 5px;
}
.all-inbox-title-wrapper .all-inbox-title .kw-text {
    font-weight: bold;
    text-transform: capitalize;
}
.all-inbox-title-wrapper .all-inbox-sub-title {
    font-size: 1.5em;
}

.all-inbox-email-logo-wrap {
    display: flex;
}

.all-inbox-email-logo-wrap picture {
    margin-right: 20px;
}

.all-inbox-email-logo-wrap picture:last-child {
    margin-right: 0;
}

.all-inbox-email-logo-wrap picture .multi-img {
    width: 100%;
    height: 100%;
    max-width: 175px;
    max-height: 60px;
    object-fit: contain;
}

@media (max-width: 767px) {
    .all-inbox-title-wrapper .all-inbox-title {
        font-size: 1.4rem;
    }
    .all-inbox-title-wrapper .all-inbox-sub-title {
        font-size: 1.2em;
    }
    .all-inbox-email-logo-wrap picture .multi-img {
        max-height: 50px;
    }
}
@media (max-width: 360px) {
    .all-inbox-title-wrapper .all-inbox-title {
        font-size: 1.2rem;
    }
    .all-inbox-title-wrapper .all-inbox-sub-title {
        font-size: 1rem;
    }
}
/* All Inbox Email Changes ends */

/* EDU OPT-in CSS starts */
#survey-wrapper {
    margin-bottom: 20vh;
}

#survey-wrapper .optin-select-wrapper.MuiInput-root {
    width: 80%;
    margin: 20px auto 5px auto;
    display: block;
    height: 58px;
    border: 1px solid #88a1ad;
    background: #fff;
    border-radius: 4px;
    text-align: center;
}

#survey-wrapper .optin-select-wrapper.Mui-focused {
    border: 1px solid #88a1ad;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

#survey-wrapper .optin-select-wrapper.MuiInput-underline:before,
#survey-wrapper .optin-select-wrapper.MuiInput-underline:after,
#survey-wrapper .optin-select .MuiInput-underline:before,
#survey-wrapper .optin-select .MuiInput-underline:after {
    border-bottom: none;
}

#survey-wrapper .optin-select-wrapper .MuiInput-input,
.optin-select-cta {
    width: 100%;
    height: 56px;
    padding: 19px 15px;
    box-sizing: border-box;
    color: #828c92;
    background: #fff;
    border-radius: 4px;
    text-align: center;
}

@media (min-width: 600px) {
    .optin-select-cta {
        width: 50%;
        display: flex;
        justify-content: center;
    }
}

.optin-select {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sv_main form {
    padding: 0 15px;
}

.optin-select-cta.blue-cta {
    border-radius: 28px;
    border: solid 1px #e57500;
    background-color: #ff8200;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8200), to(#ff8200));
    background-image: -webkit-linear-gradient(top, #ff8200, #ff8200);
    background-image: linear-gradient(to bottom, #ff8200, #ff8200);
    font-size: 24px;
    margin-top: 10px;
}

.select-btn {
    top: 20px;
}

.sv_main .optin-select-cta.blue-cta:hover {
    border-radius: 28px;
    border: solid 1px #e57500;
    background-color: #ff8200;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8200), to(#ff8200));
    background-image: -webkit-linear-gradient(top, #ff8200, #ff8200);
    background-image: linear-gradient(to bottom, #ff8200, #ff8200);
}

.optin-select .error-wrapper {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.optin-select .err-text {
    color: #e10000;
}

.dynflow.link-btn {
    display: flex;
    width: 100%;
}

.dynflow.link-btn:hover {
    text-decoration: none;
}

.optin-select .err-icon {
    width: 15px;
    height: 15px;
    padding-right: 10px;
}

.optin-select-cta.blue-cta .sv_main button:hover {
    border-radius: 28px;
    border: solid 1px #e57500;
    background-color: #ff8200;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8200), to(#ff8200));
    background-image: -webkit-linear-gradient(top, #ff8200, #ff8200);
    background-image: linear-gradient(to bottom, #ff8200, #ff8200);
}

.optin-select-cta.green-cta {
    border: solid 1px #458e00;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#55ae00), to(#4d9e00));
    background-image: -webkit-linear-gradient(top, #55ae00, #4d9e00);
    background-image: linear-gradient(to bottom, #55ae00, #4d9e00);
    font-size: 24px;
    margin-top: 10px;
    width: 80%;
}

.dialog-paper-rounded {
    border-radius: 8px !important;
}

.dialog-paper-tint {
    background-color: unset !important;
}

.listings-bg-dialog .app-wrapper .input-with-icon {
    padding: 10px;
}

.listings-bg-dialog .app-wrapper .jobcase-survey {
    padding: 0 25px 0 45px;
}

.listings-bg-dialog .loader-wrapper {
    width: 100vw;
}

.listings-bg-dialog .app-wrapper .error p.Mui-error {
    bottom: 50px;
    width: 100%;
    padding: 3px 0;
    position: absolute;
    position: absolute;
    /* bottom: -7px; */
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    white-space: nowrap;
}

.listings-bg-dialog .app-wrapper .zip-error p.Mui-error {
    bottom: -10px;
    width: 100%;
    padding: 3px 0;
    position: absolute;
    position: absolute;
    /* bottom: -7px; */
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    white-space: nowrap;
}

.listings-bg-dialog.mb .MuiDialog-container {
    height: 100vh;
    overflow: scroll;
    scroll-behavior: smooth;
}

.listings-bg-dialog.listings-bg-offer-path .MuiPaper-elevation24 {
    box-shadow: none;
}

.listings-bg-dialog.listings-bg-offer-path .offer-path-container {
    background-color: transparent;
}

.listings-bg-dialog.listings-bg-offer-path .MuiPaper-root {
    background-color: transparent;
    position: relative;
    top: 54px;
}

.listings-bg-dialog.listings-bg-offer-path.email-preference-dialog .MuiPaper-root {
    background-color: transparent;
    position: relative;
    top: 60px;
    width: 600px;
}

@media (max-width: 500px) {
    .listings-bg-dialog.listings-bg-offer-path.email-preference-dialog .MuiPaper-root {
        background-color: transparent;
        position: relative;
        top: 40px;
        width: 100%;
        margin: 20px;
    }
}

.listings-bg-dialog.bottom-aligned-interstitial .MuiPaper-root {
    top: unset !important;
    margin: 0 !important;
    width: 100%;
    position: absolute;
    bottom: 0;
    border-radius: 12px 12px 0 0;
}

.listings-bg-dialog.listings-bg-offer-path.rg-static-images .MuiPaper-root {
    top: 8px;
}

.listings-bg-dialog.listings-bg-offer-path .MuiDialog-paper::-webkit-scrollbar {
    display: none;
}

.non-fixed-container > .MuiDialogContent-root {
    overflow-x: hidden;
}

.listings-bg-dialog.listings-bg-offer-path .matches-title {
    font-size: 18px;
}

.listings-bg-dialog .app-wrapper .input-with-icon.error {
    margin-top: 23px;
}

.listings-bg-dialog .app-wrapper .align-input-side.error {
    margin-bottom: 15px;
}

.listings-bg-dialog .MuiDialog-paperWidthSm {
    width: calc(100% - 20px);
}

.listings-bg-dialog #survey-container {
    padding-bottom: 15px;
}

.listings-bg-dialog .MuiDialogContent-root {
    padding: 0;
}

.listings-bg-dialog .MuiDialog-paperScrollPaper {
    margin: 0 15px;
}

.listings-bg-dialog.xml-user-information-popup .MuiDialog-paperScrollPaper {
    margin: auto 15px;
}
.xml-user-information-popup ::-webkit-scrollbar {
    display: none;
}

.listings-bg-dialog.ios-safari .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 144px);
}

.listings-bg-dialog.xml-user-information-popup.xml-user-information-popup-variant2
    .MuiDialog-paperScrollPaper {
    display: flex;
    align-self: flex-end;
    top: unset !important;
    margin: 0;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

@media (min-width: 600px) {
    .listings-bg-dialog .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 110px);
    }

    .listings-bg-dialog.rg-static-images .MuiDialog-paperScrollPaper {
        max-height: 100%;
    }

    .listings-bg-dialog .MuiDialog-paperWidthSm {
        width: auto;
    }

    .listings-bg-dialog-model-change .MuiDialog-paperWidthSm {
        width: 100% !important;
    }

    .listings-bg-dialog-model-change .app-wrapper .input-with-icon,
    .listings-bg-dialog-model-change .app-wrapper .MuiTextField-root {
        padding: 20px !important;
        height: 64px !important;
    }

    .reg-screen-2 {
        min-width: 600px;
    }

    .listings-bg-dialog .loader-wrapper {
        width: 600px;
    }
}

@media (min-width: 960px) {
    .listings-bg-dialog .MuiDialog-paperWidthSm {
        max-width: 960px;
    }

    .listings-bg-dialog-model-change .MuiDialog-paperWidthSm {
        max-width: 1156px !important;
    }

    .reg-screen-2 {
        min-width: 960px;
    }
}

.listings-bg-dialog .MuiDialog-scrollPaper {
    align-items: flex-start;
}

.image-in-bg {
    background-color: rgba(0, 0, 0, 0.5);
}

.listings-bg-dialog .listings-tp {
    max-height: 40px;
    background-color: #f6f6f6;
}

.listings-bg-dialog .listings-tp img {
    max-height: 20px;
}

.listings-bg-dialog .MuiDialogContent-root:first-child {
    padding-top: 0;
}

.rg-wrapper.MuiGrid-root {
    margin: 0 auto;
}

.rg-wrapper .bg-img {
    width: 100%;
}

.rg-wrapper .bg-container {
    background-color: white;
    margin: 0 15px;
    border: 4px;
    border-radius: 8px;
}

.rg-wrapper .box-img-wrapper {
    font-size: 17px;
}

.rg-wrapper .underline-input {
    padding: 0;
    margin: 0 auto;
    text-align: center;
    width: 134px;
    height: 50px;
    border: none;
}

.listings-bg-dialog .rg-wrapper .underline-input {
    width: 115px;
    background-color: #f6f6f6;
}

.listings-bg-dialog .rg-wrapper .underline-input.rg-prelander-listingsBg {
    width: 150px;
    background-color: transparent;
}

.listings-bg-dialog .rg-wrapper .underline-input .MuiInputBase-input {
    font-size: 22px;
    text-decoration: none;
    border-bottom: 2px solid #006dca;
}

.rg-wrapper .underline-input .MuiInputBase-input {
    text-decoration: underline;
    color: #006dca;
    font-weight: bold;
    font-size: 26px;
    line-height: 1.5;
    letter-spacing: 1px;
}

.app-wrapper .input-with-icon.underline-input p.Mui-error {
    bottom: 0px;
}

.app-wrapper .input-with-icon.underline-input.rg-prelander-listingsBg-error p.Mui-error {
    bottom: -6px;
}

.rg-wrapper .bg-container .kw-btn {
    padding: 10px;
    border-radius: 4px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#52ae01), to(#4b9e06));
    background-image: -webkit-linear-gradient(top, #52ae01, #4b9e06);
    background-image: linear-gradient(to bottom, #52ae01, #4b9e06);
    color: white;
    margin: 10px 20px 0;
    text-transform: uppercase;
    font-size: 22px;
}

.autocomplete-wrapper .MuiTextField-root {
    border: none;
    padding: 0;
}

.autocomplete-wrapper .MuiInputLabel-outlined {
    padding-left: 20px;
}

.autocomplete-wrapper .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
    width: 90%;
    margin: auto;
}

/*White Background css starts*/

.root-app-wrapper.myJobsCorner.app-bg-white {
    background-color: #fff;
}

.app-wrapper .white-bg-input .input-with-icon {
    padding: 0;
    border: none;
    margin-bottom: 10px;
    border-radius: 4px;
}

.app-wrapper .white-bg-input .input-with-icon .MuiSelect-select.MuiSelect-outlined {
    padding: 14px 40px 14px 14px;
    background-color: #fff;
    margin-top: 0;
    border: 2px solid transparent;
}

.app-wrapper .white-bg-input .date-input {
    padding: 0;
    margin-bottom: 10px;
    border: none;
    background: transparent;
}

.app-wrapper .white-bg-input .input-with-icon .MuiOutlinedInput-input {
    padding: 16px 14px;
}

.app-wrapper .white-bg-input .input-with-icon .MuiFormLabel-root.Mui-focused.MuiInputLabel-shrink {
    color: #888;
    background: #fff;
    padding: 2px;
    border-radius: 4px 4px 0 0;
}

.app-wrapper
    .white-bg-input
    .input-with-icon
    .MuiOutlinedInput-root.MuiInputBase-formControl.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border: 2px solid #3881c5;
}

.app-wrapper .white-bg-input .MuiOutlinedInput-notchedOutline,
.app-wrapper .white-bg-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 2px solid #ababab;
}

.app-wrapper .white-bg-input .input-with-icon .MuiFormLabel-root.Mui-error.MuiInputLabel-shrink,
.app-wrapper
    .white-bg-input
    .input-with-icon
    .MuiFormLabel-root.MuiFormLabel-filled.Mui-focused.MuiInputLabel-shrink.Mui-error {
    color: #888;
    background: #fff;
    padding: 2px;
    border-radius: 4px 4px 0 0;
}

.app-wrapper
    .white-bg-input
    .input-with-icon
    .MuiOutlinedInput-root.MuiInputBase-formControl.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border: 2px solid #e10000;
}

.app-wrapper .white-bg-input .input-with-icon .MuiFormLabel-filled {
    color: #888;
    background: #fff;
    padding: 2px;
    border-radius: 4px 4px 0 0;
}

.app-wrapper
    .white-bg-input
    .input-with-icon
    .MuiOutlinedInput-root.MuiOutlinedInput-adornedEnd
    .MuiOutlinedInput-notchedOutline,
.app-wrapper
    .white-bg-input
    .input-with-icon
    .MuiFormLabel-filled
    + .MuiInputBase-formControl
    .MuiSelect-root,
.app-wrapper
    .white-bg-input
    .input-with-icon
    .MuiFormLabel-filled
    + .MuiInputBase-formControl
    .MuiOutlinedInput-notchedOutline {
    border: 2px solid #5cb910;
}

.app-wrapper .white-bg-input .date-input .input-with-icon label {
    font-size: 16px !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    color: #807f7f !important;
    background: #fff;
    padding: 0 5px;
}

.app-wrapper .white-bg-input .input-label {
    display: none;
}

@media (max-width: 767px) {
    .app-wrapper .white-bg-input .date-input .input-with-icon {
        padding: 0;
    }
}

.xml-header-wrapper
    + .non-fixed-container
    .app-wrapper
    .date-input
    fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
}

.xml-header-wrapper + .non-fixed-container .app-wrapper .date-input .input-with-icon {
    padding: 0;
}

.xml-header-wrapper
    + .non-fixed-container
    .app-wrapper
    .date-input
    .input-with-icon
    .MuiSelect-select {
    padding: 10px 0;
    margin-top: 6px;
}

.white-bg-flow .disclaimer-wrapper h3 {
    border: 2px solid #ababab !important;
    border-radius: 3px;
}

.app-wrapper .agree-reg2 .checkbox-btn.white-bg-btn {
    background: #f1f4f5;
    border: 2px solid #ababab;
    color: #8d9da2;
    padding: 5px 8px;
}

.app-wrapper .agree-reg2 .checkbox-btn.white-bg-btn .MuiCheckbox-root {
    color: #ababab;
    padding: 0 8px 0 0;
}

.app-wrapper .white-bg-input .date-input .input-with-icon label.Mui-error {
    color: #e10000 !important;
}

/*White Background css ends*/

@media (max-width: 450px) {
    .rg-wrapper .box-img-wrapper {
        font-size: 15px;
    }

    .rg-wrapper .box-img-wrapper .box-img {
        max-width: 20px;
        max-height: 14px;
    }

    .rg-wrapper .bg-container .kw-btn {
        padding: 5px 10px;
        font-size: 20px;
    }

    .rg-wrapper .rg-container {
        top: -115px;
    }
}

@media (max-width: 320px) {
    .rg-wrapper .box-img-wrapper {
        font-size: 12px;
    }

    .rg-wrapper .box-img-wrapper .box-img {
        max-width: 17px;
        max-height: 13px;
    }

    .rg-wrapper .title {
        font-size: 14px;
    }

    .rg-wrapper .bg-container .kw-btn {
        margin: 5px 20px 0;
    }
}

@media (max-width: 767px) {
    .optin-select-cta.green-cta {
        width: 100%;
    }
}

#survey-wrapper .optin-select-wrapper.MuiInput-root.error {
    border-color: #e10000;
}

/* #survey-wrapper .optin-select-wrapper.Mui-focused .MuiInput-input {

} */
.MuiPopover-root .optin-options {
    text-align: center;
    display: block;
    color: #41434d;
    font-size: 22px;
    font-weight: 500;
    padding: 10px;
    margin: 0px 15px;
}

.MuiPopover-root .optin-options.underline {
    text-decoration: underline;
}

.MuiPopover-root .optin-options:first-child {
    border-top: 1px solid #e6ecef;
}

.MuiPopover-root .MuiPaper-elevation8 {
    box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.1);
}

.MuiPopover-root .MuiPaper-rounded {
    border-radius: 0px 0px 4px 4px;
}

.MuiPopover-root .MuiPaper-elevation8 .MuiList-padding {
    padding: 0;
}

#survey-wrapper .optin-select-wrapper .MuiSelect-icon {
    display: none;
}

#survey-wrapper .optin-select-wrapper .MuiInput-input:before {
    padding: 10px;
    position: absolute;
    right: 15px;
    top: 18px;
    background-image: url(/images/grey-down-arrow.svg);
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
}

.optin-cta-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
}

.optin-cta-wrapper .optin-block {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.optin-cta-wrapper button.optin-options {
    width: 230px;
    height: 60px;
    font-size: 24px;
    line-height: 20px;
    letter-spacing: 1.47px;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

label.optin-options.grey-cta.sv_q_radiogroup_label {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#bcbcbc), color-stop(97%, #a9a9a9));
    background-image: -webkit-linear-gradient(top, #bcbcbc, #a9a9a9 97%);
    background-image: linear-gradient(to bottom, #bcbcbc, #a9a9a9 97%);
    /* margin-right: 5px; */
    width: 45%;
    padding: 12px;
}

label.optin-options.green-cta.sv_q_radiogroup_label,
label.optin-options.blue-cta.sv_q_radiogroup_label {
    width: 45%;
    padding: 12px;
}

.optin-cta-wrapper button.optin-options.green-cta {
    /* margin-left: 5px;
    border: solid 1px #458e00;
    background-image: linear-gradient(to bottom, #55ae00, #4d9e00); */
    background-color: #5cb910;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#55ae00), to(#4d9e00));
    background-image: -webkit-linear-gradient(top, #55ae00, #4d9e00);
    background-image: linear-gradient(to bottom, #55ae00, #4d9e00);
    font-family: 'Roboto', sans-serif;
    width: 45%;
}

.optin-cta-wrapper button.optin-options.blue-cta {
    margin-left: 5px;
    border-radius: 28px;
    border: solid 1px #e57500;
    background-color: #ff8200;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8200), to(#ff8200));
    background-image: -webkit-linear-gradient(top, #ff8200, #ff8200);
    background-image: linear-gradient(to bottom, #ff8200, #ff8200);
    font-weight: bold;
}

/*EDU OPT-in CSS Ends*/

.app-wrapper .white-bg-input.repeat-user-wrapper .input-with-icon .MuiOutlinedInput-input {
    margin-left: 16px;
    padding-left: 6px;
}

@media (max-width: 1024px) {
    /* #survey-wrapper .optin-select-wrapper.MuiInput-root {
        width: 360px;
    } */
    .desktop-hide {
        display: inline-block;
    }

    .app-wrapper .desktop-bold {
        font-weight: normal;
    }

    .container {
        width: auto;
        margin: 0 auto;
        padding: 0 15px;
    }

    .hand-bg-wrapper.hand-bg {
        width: 100%;
        height: 150px;
    }

    .iframe-wrapper .container {
        width: auto;
        margin: 0 auto;
        padding: 10px 15px;
    }

    .home-page-wrapper .banner-fold-wrapper .banner-fold,
    .home-page-wrapper .article-blocks-contact-wrapper .article-blocks-contact-fold,
    .email-opt-page-wrapper .email-opt-fold,
    .apply-job-form {
        flex-direction: column;
    }

    .home-page-wrapper .banner-img-wrapper img,
    .home-page-wrapper
        .article-blocks-contact-wrapper.all-articles
        .article-blocks-contact-fold
        .article-blocks.width50
        .banner-img-wrapper
        img {
        width: 100%;
        margin: 0 0 20px;
    }

    .home-page-wrapper .article-blocks-contact-wrapper img {
        margin: 0;
    }

    .home-page-wrapper .banner-content-wrapper {
        margin: 0;
    }

    .home-page-wrapper
        .article-blocks-contact-wrapper
        .article-blocks-contact-fold
        .article-blocks {
        margin: 0 0 20px 0;
        flex: 1 1 100%;
    }

    .home-page-wrapper
        .article-blocks-contact-wrapper.all-articles
        .article-blocks-contact-fold
        .article-blocks {
        flex: 1 1 100%;
    }

    .home-page-wrapper
        .article-blocks-contact-wrapper.all-articles
        .article-blocks-contact-fold
        .article-blocks.width50 {
        flex-direction: column;
        flex: 1 1 100%;
    }

    .email-opt-page-wrapper {
        height: 100%;
    }

    .email-opt-content .contactus-form-wrapper.email-opt-form-wrapper {
        width: 100%;
    }

    .email-opt-img-wrapper img {
        width: 100%;
        margin-top: 50px;
    }

    .email-opt-img-order {
        order: 2;
    }

    .email-opt-page-wrapper .email-opt-fold {
        padding: 35px 10px 10px;
    }

    .email-opt-content .main-title {
        line-height: 32px;
    }

    .unsubscribe-wrapper .email-opt-img-wrapper img {
        width: 100%;
        margin-top: 0;
    }

    .unsubscribe-wrapper .title-wrapper .unsubscribe-sub-title {
        width: 240px;
        margin: 20px auto 0;
    }

    .unsubscribe-wrapper .title-wrapper {
        margin-bottom: 40px;
    }

    .contactus-wrapper .email-opt-content .contactus-form-wrapper.email-opt-form-wrapper {
        width: 100%;
    }

    .contactus-wrapper .contactus-form-wrapper .contactus-form input.form-control:first-child {
        margin-right: 0;
    }

    .contactus-wrapper .contactus-form-wrapper .contactus-form input.form-control {
        width: 100%;
    }

    .contactus-wrapper .email-opt-content .main-title {
        margin-top: 40px;
    }

    footer a {
        font-size: 11px;
    }

    .email-opt-page-wrapper.iframe-wrapper {
        height: 100%;
    }

    .iframe-wrapper .container iframe {
        width: 100%;
        border: none;
    }

    .email-opt-page-wrapper.iframe-wrapper .article-img {
        height: 200px;
    }

    .listLogoWrapper {
        flex-direction: column;
    }

    .listLogoWrapper .MuiList-padding {
        width: 100%;
    }

    .listLogoWrapper .MuiAvatar-square {
        padding: 30px 0;
        height: auto;
    }

    .job-listing-wrapper {
        padding: 20px;
    }

    .job-listing-wrapper .job-listing-fold {
        padding: 25px;
    }

    .job-listing-wrapper .job-listing-fold .job-listing-title {
        font-size: 22px;
        line-height: 22px;
        margin-bottom: 25px;
    }

    .job-listing-fold .apply-job-form .input-wrap input,
    .apply-job-form .green-btn {
        height: 40px;
        margin-bottom: 0px;
    }

    .apply-job-form .input-wrap > label {
        font-size: 14px;
    }

    .apply-job-form .input-wrap > label.hidden {
        display: none;
    }

    .job-listing-wrapper .job-listing-fold .disableBlur {
        padding: 0;
    }

    .explore-jobs {
        display: block;
    }

    .explore-jobs-btns .MuiButton-textPrimary {
        width: auto;
        font-size: 1.2rem;
    }

    .hand-bg-wrapper.hand-bg {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .user-agreement-wrapper {
        width: auto;
    }

    .MuiGrid-container .sub-footer-wrapper .MuiGrid-grid-xl-3 {
        max-width: 100%;
    }

    .app-wrapper .padding-responsive-wrapper {
        padding: 0 15px;
        margin: 0;
        box-sizing: border-box;
    }

    .app-wrapper .padding-responsive-wrapper .logo-responsive .MuiAvatar-root {
        margin: 0;
    }

    .app-wrapper .padding-responsive-wrapper h2 {
        line-height: normal;
        margin-bottom: 10px;
    }

    .app-wrapper .padding-responsive-wrapper h1 {
        line-height: normal;
        margin: 10px 0;
    }

    .padding-responsive-wrapper .logo-responsive {
        margin-top: 15px;
    }

    /* .app-wrapper .disclaimer-with-error-msg {
        margin: 0 0 10px;
    } */
    /* .app-wrapper .MuiGrid-direction-xs-column {
        position: relative;
        height: 100%;
    }
    .MuiGrid-container .sub-footer-wrapper {
        position: absolute;
        bottom: 0px;
    } */
    .contactus-wrapper .contactus-info {
        padding: 35px 40px;
    }

    .app-wrapper .align-input-side {
        display: block;
        margin-bottom: 0;
    }

    .app-wrapper .align-input-side .mobile-align-input {
        margin: 0 auto;
    }
}

@media (max-width: 640px) {
    .sv_qstn fieldset .sv-q-col-2 {
        padding-right: 0;
    }

    .overlay-survey-loader .overlay-content .logo-zoom {
        width: 6rem;
    }
}

@media (max-width: 400px) {
    .app-wrapper .date-input .input-with-icon label {
        overflow: hidden;
        text-overflow: ellipsis;
        flex-wrap: wrap;
        width: 60px;
    }

    .app-wrapper .optin-cta-wrapper button.optin-options {
        width: 50%;
        font-size: 18px;
        height: 50px;
        line-height: normal;
    }
}

@media (max-width: 767px) {
    .app-wrapper .date-input .input-with-icon {
        padding: 16px 0 16px 0;
    }

    /* .app-wrapper .date-input .input-with-icon .input-icon img.calender-img {
        top: 45%;
    } */
    .app-wrapper .date-input .input-with-icon .MuiSelect-icon {
        right: -2px;
    }

    #survey-wrapper .optin-select-wrapper.MuiInput-root {
        width: 100%;
    }

    .optin-cta-wrapper {
        display: flex;
        justify-content: space-betweens;
    }

    label.optin-options.green-cta.sv_q_radiogroup_label,
    label.optin-options.blue-cta.sv_q_radiogroup_label {
        width: 45%;
        font-size: 22px;
        height: 54px;
        line-height: normal;
    }

    label.optin-options.grey-cta.sv_q_radiogroup_label {
        width: 45%;
        font-size: 22px;
        height: 54px;
        line-height: normal;
    }

    /***Taboola***/
    .taboola-lander-wrapper .taboola-lander .first-fold-wrapper {
        display: flex;
    }

    .taboola-lander-wrapper .taboola-lander .first-fold-wrapper .first-fold-left-section {
        margin-right: 10px;
        text-align: left;
    }

    .taboola-lander-wrapper .taboola-lander .first-fold-wrapper .first-fold-right-section {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .taboola-lander-wrapper .taboola-lander .first-fold-wrapper .top-text {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0;
    }

    .taboola-lander-wrapper .taboola-lander .content-wrapper .content-info {
        font-size: 14px;
        line-height: 20px;
        color: #616161;
        margin-bottom: 20px;
    }

    .taboola-lander-wrapper .content-wrapper .taboola-amazon-bg {
        width: 100%;
        margin: 15px 0 10px;
    }

    .rg-wrapper.taboola-lander-wrapper .underline-input {
        margin: 0;
        width: 100px;
        margin-bottom: 0px;
    }

    .rg-wrapper.taboola-lander-wrapper .underline-input .MuiInputBase-input {
        font-size: 18px;
    }

    .taboola-lander-wrapper .taboola-lander .bg-container .desktop-form-align {
        display: block;
        margin-bottom: 20px;
    }

    .taboola-lander-wrapper .taboola-lander .bg-container .desktop-form-align .desktop-version {
        width: 100%;
    }

    .taboola-lander-wrapper
        .taboola-lander
        .bg-container
        .desktop-form-align
        .desktop-version.desktop-input,
    .taboola-lander-wrapper
        .taboola-lander
        .bg-container
        .desktop-form-align
        .desktop-version.desktop-cta
        .MuiButton-textPrimary {
        margin: 0;
    }

    .taboola-lander-wrapper
        .taboola-lander
        .bg-container
        .desktop-form-align
        .desktop-version.desktop-cta
        .MuiButton-textPrimary {
        margin-top: 10px;
    }

    .taboola-lander-wrapper .taboola-lander .flex-wrap {
        display: block;
    }

    .taboola-lander-wrapper .taboola-lander .content-wrapper.order-warpper .order1 {
        display: block;
        order: inherit;
        margin: 15px 0 5px;
    }

    .taboola-lander-wrapper .taboola-lander .desktop-margin,
    .taboola-lander-wrapper .taboola-lander .margin-right,
    .taboola-lander-wrapper .taboola-lander .margin-left {
        margin: 0;
    }

    .app-wrapper
        .taboola-lander-wrapper
        .taboola-lander
        .first-fold-wrapper
        .first-fold-left-section
        .zip-input-wrapper
        .input-with-icon.underline-input
        p.Mui-error {
        bottom: -13px;
        font-size: 8px;
    }

    /***Taboola***/
}

@media (max-width: 359px) {
    label.optin-options.green-cta.sv_q_radiogroup_label,
    label.optin-options.blue-cta.sv_q_radiogroup_label,
    label.optin-options.grey-cta.sv_q_radiogroup_label {
        height: auto;
        font-size: 18px;
        padding: 10px 5px;
    }
}

@media (max-width: 359px) {
    .app-wrapper .padding-responsive-wrapper .logo-responsive .MuiAvatar-root {
        width: 70px;
        height: 52px;
    }

    .app-wrapper .padding-responsive-wrapper .logo-responsive .loader-wrapper {
        margin: 15px auto;
    }

    .app-wrapper .padding-responsive-wrapper h2 {
        margin-bottom: 5px;
        font-size: 1rem;
    }

    .app-wrapper .padding-responsive-wrapper h1 {
        line-height: normal;
        margin: 10px 0 5px;
        font-size: 1.2rem;
    }

    .contactus-wrapper .contactus-info {
        padding: 25px 15px;
    }

    .rg-wrapper.taboola-lander-wrapper .underline-input .MuiInputBase-input {
        font-size: 18px;
        line-height: 24px;
    }

    .taboola-lander-wrapper .content-wrapper .content-info {
        font-size: 14px;
        line-height: 20px;
    }
}

@media (min-width: 1400px) {
    .footer-main-wrap {
        margin-top: 130px;
    }
}

/***Taboola Lander CSS Starts***/
.rg-wrapper.taboola-lander-wrapper .bg-container {
    background-color: #f2f3f4;
    margin: 0;
    border: 0;
    border-radius: 0;
}

.rg-wrapper.taboola-lander-wrapper .rg-container {
    top: 0;
}

.taboola-lander-wrapper .first-fold-wrapper {
    display: block;
}

.taboola-lander-wrapper .first-fold-wrapper .first-fold-left-section {
    margin-right: 0;
    text-align: center;
}

.taboola-lander-wrapper .first-fold-wrapper .first-fold-left-section .zip-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.taboola-lander-wrapper .first-fold-wrapper .first-fold-right-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.taboola-lander-wrapper .first-fold-wrapper .top-text {
    font-size: 24px;
    line-height: normal;
    margin-bottom: 20px;
}

.taboola-lander-wrapper .underline-input {
    padding: 0;
    margin: 0 auto;
    text-align: left;
    width: 123px;
    height: auto;
    border: none;
    background: transparent;
    margin-bottom: 20px;
}

.taboola-lander-wrapper .underline-input .MuiInputBase-input {
    text-decoration: underline;
    color: #0077cc;
    font-size: 24px;
    line-height: normal;
}

.taboola-lander-wrapper .content-wrapper .content-info {
    font-size: 16px;
    line-height: 22px;
    color: #616161;
    margin-bottom: 40px;
}

.taboola-lander-wrapper .anchor-link {
    color: #0077cc;
    cursor: pointer;
    text-decoration: underline;
}

.taboola-lander-wrapper .content-wrapper .content-title {
    margin: 10px 0 0;
    font-size: 18px;
    text-decoration: underline;
    line-height: 24px;
}

.taboola-lander-wrapper .content-wrapper .content-info.small-margin {
    margin-top: 5px;
}

.taboola-lander-wrapper .content-wrapper .content-info.small-margin-all {
    margin: 5px 0;
}

.taboola-lander-wrapper .content-wrapper .taboola-amazon-bg {
    width: 100%;
    margin: 0;
}

.taboola-lander-wrapper .bg-container .desktop-form-align {
    display: flex;
    margin-bottom: 50px;
}

.taboola-lander-wrapper .bg-container .desktop-form-align .desktop-version {
    width: 50%;
}

.taboola-lander-wrapper .bg-container .desktop-form-align .desktop-version.desktop-input {
    margin-right: 10px;
}

.taboola-lander-wrapper
    .bg-container
    .desktop-form-align
    .desktop-version.desktop-cta
    .MuiButton-textPrimary {
    height: 58px;
    margin-left: 10px;
}

.taboola-lander-wrapper .bg-container .desktop-form-align .desktop-version.desktop-cta,
.taboola-lander-wrapper .bg-container .desktop-form-align .desktop-version .input-with-icon {
    margin-bottom: 0;
}

.taboola-lander-wrapper .flex-wrap {
    display: flex;
}

.taboola-lander-wrapper .content-wrapper.order-warpper {
    display: flex;
    flex-direction: column;
}

.taboola-lander-wrapper .content-wrapper.order-warpper .order1 {
    /* display: flex; */
    order: 2;
}

.taboola-lander-wrapper .desktop-margin {
    margin: 60px 0;
}

.taboola-lander-wrapper .zip-align input:-webkit-autofill {
    color: #0077cc !important;
    -webkit-text-fill-color: #0077cc;
}

.app-wrapper
    .taboola-lander-wrapper
    .first-fold-wrapper
    .first-fold-left-section
    .zip-input-wrapper
    .input-with-icon.underline-input
    p.Mui-error {
    bottom: -16px;
}

.taboola-lander-wrapper .margin-right {
    margin-right: 10px;
}

.taboola-lander-wrapper .margin-left {
    margin-left: 10px;
}

/***Taboola Lander CSS Ends***/

/*** White background CSS Start ***/
.root-app-wrapper.white-bg {
    background-color: white;
}

.root-app-wrapper.myJobsCorner.verizon-grey {
    background-color: #fafafa;
}

/*** White background CSS End ***/

/*Oval Cta css starts*/
.ovalcta button {
    border-radius: 33px;
}
/*Oval Cta css ends*/
.t-alternate-titles-container {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    margin: 0 16px;
    font-size: 13px;
    padding: 5px 0 5px 0;
    line-height: 1.4;
}
.t-alternate-titles-chevron {
    right: 25px;
    position: absolute;
    bottom: 16px;
    background-color: #f3f1f3;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    padding: 4px;
}
.t-job-salary-urgent {
    color: #742f7e;
    font-size: 11px;
    font-weight: 600;
}
.t-alternate-titles-salary-wrap {
    display: inline-block;
}
.t-block {
    display: block;
}
.t-alternate-titles {
    border: none;
    text-decoration: none;
    color: inherit;
    font-size: inherit;
    padding: 10px 20px 10px 20px;
    border-top: 1px solid #ececec;
    position: relative;
}
.t-alternate-titles-label {
    color: #30183f;
    font-weight: 600;
    font-size: 17px;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.t-alternate-titles-salary-empname {
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #676767;
    font-size: 12px;
}

@media only screen and (min-width: 1260px) {
    .t-alternate-titles-container {
        max-width: 1140px;
        margin: 20px auto;
    }
}

