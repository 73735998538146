.err-page-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.err-page-content-wrapper {
    color: #363636;
    font-size: 20px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.err-page-img-wrapper {
    align-self: center;
}

.err-page-heading {
    margin-top: 20px;
    font-size: 150%;
}
