.search-bar-wrapper {
    margin: 0 auto;
    width: 100%;
}

@media (max-width: 768px) {
    .search-bar-wrapper {
        flex-direction: column;
    }

    .btn-container {
        height: 48px;
    }
}

.btn-container .search-btn {
    height: 48px;
    margin-top: 5px;
    margin-left: 8px;
}

.search-bar-wrapper > div {
    margin: 0;
}

.nrml-txt {
    color: #294964;
    font-size: 16px;
    font-weight: bold;
    /* line-height: 2; */
}

.highlight {
    font-weight: 500;
    color: #0064ff;
    font-size: 18px;
    line-height: 1.33;
}

.search-bar-wrapper .search-btn {
    width: 85%;
    height: 48px;
    /* border-radius: 4px; */
}

.MuiTypography-root.job-list-header {
    background-color: #deebf7;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #1a66c8;
    text-align: center;
}

.job-list-header .bold {
    font-weight: bold;
}

.search-bar-wrapper .MuiOutlinedInput-input {
    padding: 10px 14px;
    height: 47px;
    box-sizing: border-box;
    font-size: 14px;
}

.result-title-wrapper {
    display: block;
    margin: 0px 0 10px;
    text-align: center;
}

.job-loc {
    color: #0064ff;
}

.result-title-wrapper .result-title {
    font-size: 14px;
    color: #294964;
    font-weight: bold;
    line-height: 24px;
}

.branding-txt {
    font-size: 11px;
    background-color: #ed6a0a;
    color: #fff;
    height: 20px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    width: 130px;
    padding: 0 5px;
    position: absolute;
    right: 0;
}

.static-branding-txt {
    font-size: 11px;
    background-color: #f94f00;
    color: #fff;
    height: 20px;
    border-radius: 0 4px;
    display: inline-flex;
    align-items: center;
    width: auto;
    position: absolute;
    right: 0;
    height: 11px;
    font-family: Roboto;
    font-size: 9.6px;
    font-weight: 900;
    padding: 4px 5px;
}

.job-description-creditAd-description {
    padding: 10px 12px;
    border-radius: 2px;
    margin-top: 6px;
    background-color: #eef8ff;
}

.job-content-wrapper-creditAd {
    width: 100% !important;
}

.search-icon {
    width: 10px;
    height: 10px;
    padding: 2px 3px 0 0;
}

.divider {
    background-color: #88a1ad;
    height: 1px;
    width: 100%;
}

.jobs-list-wrapper .job-element-card {
    margin: 0 0 10px;
    padding: 10px 0px 0px 10px;
    background: #fff;
    border: 1px solid #d8e0e7;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
}

.jobs-list-wrapper .job-element-card.job-element-card-fulfillment-design {
    border: unset !important;
}
.link-container {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
}

.jobs-list-wrapper .job-element-card .job-content-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding: 0px;
    -webkit-tap-highlight-color: transparent;
    width: 80%;
}

.job-element-card.dynamic-feed {
    padding: 20px 0px 0px 10px;
    border: solid 1px #b1d1ea;
    background-color: #e8f5ff;
}

.listing-item {
    opacity: 0;
    transition: opacity 1s ease-out;
    overflow: hidden;
}

.listing-item.content {
    opacity: 1;
}

.static-listings-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.static-listings-wrapper .title {
    /* height: 16px; */
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: center;
    color: #273b4a;
    width: 100%;
}

.static-listings-wrapper .focus {
    color: #3f8dd3;
}

.job-element-card.static-feed {
    padding: 0;
    border: solid 2px #d8e0e7;
    /* border: solid 1.5px #f94f00; */
    width: 100%;
    cursor: pointer;
}

/*Week1 starts*/
.jobs-list-wrapper.static-list.week1 .job-element-card.static-feed {
    border: solid 2px #f94f00;
}

.jobs-list-wrapper.static-list.week1 .static-feed-right {
    display: none;
}

/*Week1 ends*/

/*Week2 starts*/
.jobs-list-wrapper.static-list.week2 .job-element-card.static-feed {
    border: solid 2px #54ae00;
}

.jobs-list-wrapper.static-list.week2 .static-branding-txt {
    display: none;
}

/*Week2 ends*/

/*Week3 starts*/
.jobs-list-wrapper.static-list.week3 .job-element-card.static-feed {
    border: solid 2px #54ae00;
}

.jobs-list-wrapper.static-list.week3 .static-branding-txt {
    display: none;
}

.jobs-list-wrapper.static-list.week3 .job-apply-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-right: 15px;
}

.jobs-list-wrapper.static-list.week3 .static-feed-right {
    width: 50px;
    height: 50px;
    background-color: #54ae00;
    border: 2px solid #509e07;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

@media (max-width: 360px) {
    .jobs-list-wrapper.static-list.week3 .static-feed-right {
        width: 40px;
        height: 40px;
    }
}

/*Week3 ends*/

/*Week1 starts*/
.jobs-list-wrapper.static-list.week4 .job-element-card.static-feed {
    border: solid 2px #a663f6;
}

.jobs-list-wrapper.static-list.week4 .static-branding-txt {
    background-color: #a663f6;
}

.jobs-list-wrapper.static-list.week4 .static-feed-right {
    display: none;
}

/*Week1 ends*/

.job-element-card.static-feed .job-apply-wrapper a.MuiButton-text {
    margin-right: 0;
    margin: 0;
    position: absolute;
    right: 30px;
}

.static-feed-right {
    width: 25px;
    height: 100%;
    background-color: #54ae00;
    display: flex;
    justify-content: center;
    align-items: center;
}

.job-element-card.static-feed .job-content-wrapper {
    display: flex;
    flex-direction: column;
}

.job-element-card.static-feed .job-logo-wrapper.static-logo-wrapper.MuiAvatar-root {
    width: 46px;
    height: 46px;
    background-color: #e3e9ed;
    margin-right: 10px;
    padding-right: 0;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.job-element-card.static-feed .job-logo-wrapper.static-logo-wrapper.MuiAvatar-root .static-logo {
    width: 30px;
    height: 30px;
}

.job-element-card.static-feed .job-content-title-info {
    display: flex;
    margin-bottom: 10px;
}

.job-element-card.static-feed .job-content-title-info .job-description-wrapper.static-desc {
    padding: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.job-element-card.static-feed .job-content-title-info .job-description-wrapper.static-desc h1 {
    line-height: normal;
    padding-bottom: 0;
}

.job-element-card.static-feed
    .job-content-title-info
    .job-description-wrapper.static-desc
    .job-description-creditAd-description
    h1 {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #818b92;
}

.jobs-list-wrapper .job-element-card.static-feed .job-content-wrapper {
    padding: 10px;
}

.job-element-card.static-feed .job-content-wrapper .static-anc,
.job-element-card.static-feed .job-content-wrapper .static-anc span {
    font-size: 14px;
    text-decoration: none;
    color: #408dd3;
}

.jobs-list-wrapper.static-list {
    width: 100%;
}

.job-description-wrapper.static-desc {
    width: 100%;
}

.job-element-card .job-content-wrapper .job-description-wrapper .static-anc {
    line-height: 1;
    font-size: 12px;
}

.static-brand-img {
    max-width: 100%;
    width: auto;
    max-height: 200px;
    height: auto;
}

@media (max-width: 600px) {
    .job-element-card .job-content-wrapper .job-description-wrapper .static-anc {
        font-size: 11px;
    }

    .static-brand-img {
        max-height: 100%;
    }
}

.arrow-icon {
    width: 18px;
}

.bolt-icon {
    width: 5px;
    height: 11px;
    padding-right: 2px;
    padding-top: 2px;
}

.job-element-card .job-content-wrapper .job-description-wrapper.static-desc h1 {
    line-height: 1.3;
}

.job-element-card .job-content-wrapper .job-description-wrapper {
    padding: 0px 0px 10px 0px;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
}

.job-element-card
    .job-content-wrapper
    .job-description-wrapper.job-description-wrapper-fulfillment-design {
    width: 65%;
}

.job-element-card .job-content-wrapper .job-description-wrapper h1 {
    font-size: 16px;
    font-weight: 500;
    color: #273b4a;
    line-height: 1.14;
    padding-bottom: 5px;
}

.job-element-card .job-content-wrapper .job-description-wrapper h2 {
    font-size: 12px;
    color: #41434d;
    line-height: 1.14;
    padding-bottom: 5px;
}

.job-element-card .job-content-wrapper .job-description-wrapper a {
    font-size: 12px;
    color: #3f8dd3;
    text-decoration: none;
    line-height: 1.14;
}

.jobs-list-wrapper .job-element-card .job-logo-wrapper {
    padding-right: 10px;
}

.job-logo-wrapper-fulfillment-design .MuiAvatar-rounded {
    width: 58px;
    height: 58px;
}

.job-apply-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 4px;
    width: 20%;
}

.job-apply-wrapper-fulfillment-design {
    justify-content: center;
    width: 35%;
}

.job-apply-wrapper .job-highlight {
    border-radius: 90px 0px 0px 90px;
    display: inline-flex;
    padding: 0px 7px;
    justify-content: center;
}

.job-highlightv2 {
    padding: 5px 0;
}

.job-apply-wrapper .customimg {
    width: auto;
    height: auto;
    display: inline-flex;
}

.customimg.highlightImg1 {
    max-width: 137px;
    max-height: 30px;
}

.customimg.highlightImg2 {
    max-width: 95px;
    max-height: 15px;
    padding-right: 5px;
}

.customimg.highlightImg3 {
    max-width: 137px;
    max-height: 30px;
}

.highlighttext1 {
    position: absolute;
    left: 85px;
    /* top: -5px; */
    padding: 7px 0;
    font-weight: bold;
    color: #985823;
    font-size: 14px;
}

.highlighttext2 {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #495255;
}

.highlighttext3 {
    position: absolute;
    left: 85px;
    /* top: -5px; */
    padding: 7px 0;
    font-weight: bold;
    color: #526942;
    font-size: 14px;
}

.job-apply-wrapper .job-highlight .job-highlight-text {
    padding: 2px;
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: 11px;
    letter-spacing: normal;
    color: white;
    display: inline-flex;
    align-items: center;
    font-size: 11px;
}

.job-apply-wrapper .job-highlight .job-highlight-text.job-highlight-text-fulfillment-design {
    font-size: 9px;
}

.job-apply-wrapper a.MuiButton-text {
    padding: 6px 4px;
    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
    text-transform: none;
    line-height: 1;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #53ae02;
}

.job-apply-wrapper .job-apply-button-fulfillment-design {
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(83, 174, 2, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(83, 174, 2, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 5px rgba(83, 174, 2, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(83, 174, 2, 0);
    }
}

.pagination-wrapper {
    margin: 20px 0 30px;
}

.bold {
    font-weight: 900;
    font-size: 15px;
}

.job-logo-wrapper.static-logo-wrapper.MuiAvatar-root {
    width: 48px;
    height: 48px;
    background-color: #f2f3f5;
    margin-right: 10px;
    padding-right: 0;
}

.static-logo {
    max-width: 40px;
    max-height: 40px;
    width: 100%;
    height: 100%;
}

.pagination-inner-wrapper button {
    display: inline-block;
    padding: 9px 14px;
    border: 1px solid #bbbbbb;
    margin: 3px;
    border-radius: 5px;
    color: #4a90e2;
    width: 40px;
    height: 38px;
    box-sizing: border-box;
    cursor: pointer;
}

.pagination-inner-wrapper button.active {
    background: none;
    color: #9b9b9b;
    font-weight: 600;
}

.header-title {
    font-size: 12px;
    line-height: 16px;
    color: #a3a9ae;
    word-wrap: break-word;
    margin-bottom: 0px;
    font-family: 'Roboto', sans-serif;
}

.jobs-list-wrapper .job-element-card.job-element-card-fulfillment-design .job-content-wrapper {
    width: 90%;
}

.job-element-card.job-element-card-fulfillment-design
    .job-content-wrapper
    .job-description-wrapper.job-description-wrapper-fulfillment-design {
    width: 75%;
}

.jobs-list-wrapper .job-element-card.job-element-card-fulfillment-design {
    border: unset !important;
    position: relative;
}

.job-apply-wrapper .job-highlight-fulfillment-design {
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 2px;
    min-height: 18px;
}
.job-highlight-fulfillment-design .job-highlight-picture {
    display: flex;
    align-items: center;
}

.job-element-card.job-element-card-fulfillment-design
    .job-content-wrapper
    .job-description-wrapper
    h1 {
    min-height: 28px;
}

/*MJC popup css starts*/
.mjc-pop-main-wrapper {
    position: relative;
}

.mjc-pop-main-wrapper .MuiDialog-paper {
    width: 400px;
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
    animation-duration: 1.5s;
}

@keyframes bounceInDown {
    0%,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.mjc-pop-main-wrapper .MuiDialogContent-root {
    padding: 0 !important;
}

.mjc-pop-logo {
    text-align: center;
    border-bottom: 2px solid #244766;
    padding: 20px 15px;
    background: #244766;
}

.mjc-pop-main-wrapper .mjc-pop-logo .close svg {
    width: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.mjc-pop-main-wrapper .mjc-pop-logo .close svg g g {
    fill: white;
}

.mjc-pop-logo img {
    width: 170px;
}

.disclaimer-with-error-msg h3 a {
    color: #227ddc;
}

.mjc-pop-main-wrapper .emailInput input {
    text-align: center;
}

.padding-pop-resp {
    padding: 30px 30px 20px;
}

.mjc-pop-main-wrapper .disclaimer-with-error-msg .disclaimer-wrapper p {
    margin-top: 0;
}

.xml-pop-up-container {
    cursor: pointer;
}

@media (max-width: 400px) {
    .mjc-pop-main-wrapper .disclaimer-with-error-msg .disclaimer-wrapper {
        font-size: 8px;
    }
}
@media (max-width: 500px) {
    .mjc-pop-main-wrapper .MuiDialog-paper {
        margin: 20px;
        width: 100%;
    }
    .padding-pop-resp {
        padding: 15px 15px 10px;
    }
}
/*MJC popup css ends*/
