.loader-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
    text-align: center;
}

.loader-text {
    padding: 0px 15px;
}

.err-heading {
    font-size: 120%;
    font-weight: bold;
    color: #363636;
    margin-bottom: 10px;
}

.err-wrapper {
    margin: 30px;
    text-align: center;
    color: #363636;
}

.atag {
    color: #0179ff;
    text-decoration: none;
}

.zrbSpan {
    color: #3d7565;
}
