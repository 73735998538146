/* Result */

.search-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px;
}

.search-bar-wrapper > div {
    margin: 5px;
}

.search-btn {
    height: 50px;
    /* border: 0px;
    border-radius: 2px; */
    padding: 3px 10px;
}

/* Result wrapper */

.result-title-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.result-title,
.popular-jobs-title {
    font-size: 130%;
    color: #559edf;
}

/* Jobs */

.jobs-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
}

.job-element-card {
    display: flex;
    flex-direction: row;
    height: 100px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0px;
    margin: 10px 15px;
    margin-bottom: 5px;
    justify-content: space-between;
}

.job-content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-around;
}

/* Pagination */

.pagination-wrapper {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
}

.pagination {
    padding: 0px;
    margin: 0px;
}

.pagination > li > a,
.pagination > li > a:hover,
.pagination > li > a:visited,
.pagination > li > a:focus,
.pagination > li > a:active {
    text-decoration: none;
    color: inherit;
    outline: 0;
}

.pagination > li {
    display: inline-block;
    padding: 9px;
    border: 1px solid #959596;
    margin: 3px;
    border-radius: 5px;
}

.pagination > li.disabled {
    display: none;
}

.pagination > li.active {
    background-color: rgba(0, 0, 0, 0.2);
}

/*
jobs loader
*/

.jobs-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 100;
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 120%;
    font-weight: bold;
    align-items: center;
}
