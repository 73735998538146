.root-app-wrapper {
    display: flex;
    flex-direction: column;
    /* overflow: scroll; */
    overflow: hidden;
    height: 100vh;
}

.root-app-wrapper.myJobsCorner {
    background: #f2f3f4;
    /* height: 100%; */
}

.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn {
    background-color: #fff;
}

.non-fixed-content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.sv-scroll-header {
    overflow: scroll;
    scroll-behavior: smooth;
}

.non-fixed-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.sv-fixed-top-header {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    scroll-behavior: smooth;
    height: calc(100vh - 70px);
}

.listings-bg-container {
    height: 100%;
}

.listings-bg-dialog .divider {
    background-color: #ececec;
    height: 1px;
    width: 100%;
}

.listings-bg-dialog .date-container .MuiSelect-icon {
    right: -5px;
}

.listings-bg-dialog .sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
    font-size: 20px;
    font-weight: bold;
    color: #121212;
}

.listings-bg-dialog .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn {
    background-color: #f6f6f6;
}

.app-wrapper .page-conatiner {
    display: flex;
    min-height: calc(100vh - 60px);
    flex-direction: column;
    margin-bottom: 40px;
}

.app-wrapper .bottom-aligned-interstitial-page-container {
    margin-bottom: 0 !important;
}

.listings-bg-dialog .app-wrapper .page-conatiner {
    min-height: unset;
}

@media (max-width: 600px) {
    .app-wrapper .page-conatiner {
        min-height: 100vh;
        margin-bottom: 10px;
    }

    .listings-bg-dialog .app-wrapper .page-conatiner {
        margin-bottom: 0;
    }

    .sv-fixed-top-header {
        height: auto;
    }
}

.custom-job-query .MuiAutocomplete-popupIndicator {
    color: #000;
    background-color: transparent;
    top: -20px;
    right: -50px;
}

.custom-job-query #survey-autocomplete-option {
    padding: 0;
    margin: 0;
    height: 56px;
    border: none;
    /* background-color: transparent; */
    position: relative;
    top: -15px;
    color: #000;
}

.custom-job-query.sf-jb-category .MuiAutocomplete-popupIndicator {
    top: -4px;
}

.custom-job-query .MuiAutocomplete-popupIndicator:hover {
    background-color: transparent;
}

.custom-job-query .MuiAutocomplete-popupIndicatorOpen {
    transform: rotate(0);
}

.custom-job-query.sf-jb-category #survey-autocomplete-option {
    top: 0;
}

.optin-cta-wrapper .optin-block.job-category-block {
    flex-direction: row;
}

.optin-block.job-category-block label.job-category.green-cta.sv_q_radiogroup_label {
    width: 48%;
    height: auto;
}

@media (max-width: 600px) {
    .optin-cta-wrapper .optin-block.job-category-block {
        flex-direction: column;
    }

    .optin-block.job-category-block label.job-category.green-cta.sv_q_radiogroup_label {
        width: 100%;
        height: auto;
    }
}

.footer-wrapper {
    height: 50px;
    flex-shrink: 0;
}

.input-with-icon.emailInput > div {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    height: 100%;
}

.input-with-icon.emailInput input#email {
    background: transparent;
    border: none;
    bottom: 2px;
    padding-left: 0.25em;
    padding-right: 0.5em;
}
