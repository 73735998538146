.interstitial-ads-main-wrapper {
    background-color: rgba(0, 0, 0, 0.8);
}
.interstitial-ads-main-wrapper .MuiDialog-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 225px;
}
.interstitial-ads-jobs-conatiner .job-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}
.interstitial-ads-main-wrapper .MuiDialog-paperScrollPaper {
    overflow-y: inherit;
    height: calc(100% - 50px);
    background: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 10px;
    /* max-width: 400px; */
}

.interstitial-ads-block {
    width: 330px;
    background: white;
    text-align: center;
    position: relative;
    border-radius: 4px;
}

.interstitial-ads-block .interstitial-ads-info-block {
    background-color: white;
    border-radius: 2px;
}

.interstitial-ads-block .interstitial-ads-sub-info {
    font-size: 16px;
    color: #294964;
    text-align: center;
    line-height: 1.2;
    margin: 0;
}
.interstitial-ads-block .MuiDialogContent-root {
    padding: 0px;
}

.interstitial-ads-block .interstitial-ads-btn-wrapper {
    background: white;
    padding: 0 24px 20px;
    position: relative;
    border-radius: 0 0 4px 4px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.interstitial-ads-block .interstitial-ads-sub-info .bold-text {
    font-size: 22px;
    font-weight: bold;
    line-height: 2.2;
}

.interstitial-ads-block.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
    animation-duration: 1.5s;
}
.interstitial-ads-jobs-conatiner .jobs-list-wrapper {
    display: flex;
    flex-direction: column;
}
.interstitial-ads-jobs-conatiner .jobs-list-wrapper .job-element-card {
    display: flex;
}
.interstitial-ads-jobs-conatiner .jobs-list-wrapper .job-content-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    flex-direction: column;
    height: 100%;
}
.interstitial-ads-jobs-conatiner .job-description-container .job-location {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 13px;
    color: #688093;
    line-height: 1.88;
    text-align: left;
}
.interstitial-ads-jobs-conatiner .job-description-container .job-description {
    color: #273b4a;
    line-height: 20px;
    text-align: left;
    font-weight: 500;
}

.interstitial-ads-jobs-conatiner .jobs-list-wrapper .job-logo-wrapper {
    display: flex;
    justify-content: center;
    padding-right: 10px;
}
.interstitial-ads-jobs-conatiner .jobs-list-wrapper .job-description-wrapper {
    display: flex;
}
.interstitial-ads-jobs-conatiner
    .jobs-list-wrapper
    .job-description-wrapper
    .job-description-container {
    display: flex;
    flex-direction: column;
}
.interstitial-ads-info-block .header {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    background-color: #235375;
}
.interstitial-ads-info-block .header .trending-btn {
    height: 24px;
    /* background-color: #ec6e1a; */
    border-radius: 2px;
    text-transform: capitalize;
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 500;
}
.interstitial-ads-info-block .header .trending-btn .trending-icon {
    width: 16px;
    height: 16px;
    padding-right: 10px;
}
.interstitial-ads-info-block .header .close .close-icon {
    width: 20px;
    height: 20px;
}
.interstitial-ads-jobs-conatiner .carousel .control-dots {
    display: none;
}
.interstitial-ads-jobs-conatiner .carousel .carousel-status {
    display: none;
}
.interstitial-ads-jobs-conatiner .carousel .slide {
    background-color: white;
}
.interstitial-ads-jobs-conatiner .button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    background-color: #235375;
    align-items: center;
}

.interstitial-ads-jobs-conatiner .button-container .btn {
    height: 54px;
    width: 122px;
    background-color: #618aa6;
    /* background-image: linear-gradient(to bottom, #a2b1c4, #9ba9bb); */
    color: white;
    border-radius: 2px;
    font-size: 18px;
    font-weight: bold;
}
.interstitial-ads-jobs-conatiner .button-container .btn.primary {
    width: 170px;
    height: 54px;
    border-radius: 2px;
    border: solid 1px #458e00;
    background-image: linear-gradient(to bottom, #55ae00, #4d9e00);
}
.interstitial-ads-jobs-conatiner .button-container .btn.primary .arrow-icon {
    width: 7px;
    height: 11px;
    margin-top: 12px;
    margin-left: 5px;
}
.jobs-list-wrapper .carousel-root .carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: unset;
}
.interstitial-ads-jobs-conatiner .carousel .control-next.control-arrow:before {
    content: url('/images/carousel-right-arrow.svg');
    border: 0;
    position: absolute;
    top: 25%;
    left: -18px;
}
.interstitial-ads-jobs-conatiner .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
}
.interstitial-ads-jobs-conatiner .carousel .control-prev.control-arrow:before {
    content: url('/images/carousel-left-arrow.svg');
    border: 0;
    position: absolute;
    top: 25%;
    right: -18px;
}
@-webkit-keyframes bounceInDown {
    0%,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes bounceInDown {
    0%,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@-webkit-keyframes tada {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@keyframes tada {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada;
    animation-duration: 1.5s;
    animation-iteration-count: 4;
}

@media screen and (max-width: 360px) {
    .interstitial-ads-main-wrapper .MuiDialog-container {
        height: 100%;
    }
}

@media only screen and (min-width: 361px) and (max-width: 500px) {
    .interstitial-ads-main-wrapper .MuiDialog-container {
        height: 100%;
    }
}
@media (max-width: 339px) {
    .interstitial-ads-block {
        width: calc(100vw - 20px);
        margin: 10px;
        border-radius: 2px;
    }
    .interstitial-ads-jobs-conatiner .button-container .btn.primary {
        width: 150px;
    }
    .interstitial-ads-jobs-conatiner .button-container .btn {
        width: 111px;
    }
}
